import { actionTypes } from "constants/index";

let dmsUser = sessionStorage.getItem("dmsUser");
if (!dmsUser) dmsUser = {};

export const approvers = (state = [], action) => {
  switch (action.type) {
    case actionTypes.SET_APPROVERS:
      return action.payload;
    default:
      return state;
  }
};

export const user = (state = dmsUser, action) => {
  switch (action.type) {
    case actionTypes.SET_USER:
      sessionStorage.setItem("dmsUser", action.payload);
      return action.payload;
    default:
      sessionStorage.removeItem("dmsUser");
      return state;
  }
};

export const users = (state = [], action) => {
  switch (action.type) {
    case actionTypes.SET_USERS:
      return action.payload;
    default:
      return state;
  }
};
