import React from "react";
import { Router } from "@reach/router";

import Home from "screens/home";
import Admin from "screens/admin";
import Treasuries from "screens/treasuries";
import FixedDeposits from "screens/fixed-deposits";
import IssuerSideFixedDeposits from "screens/issuer-side-fixed-deposits";
import Equities from "screens/equities";
import NotFound from "screens/NotFound";
import Disinvestments from "screens/disinvestments";
import Withdrawals from "screens/withdrawals";

const AuthedApp = () => {
  return (
    <div className="App">
      <Router primary={false}>
        <Home path="/" />
        <Admin path="admin/*" />
        <Treasuries path="treasuries/*" />
        <FixedDeposits path="fixed-deposits/*" />
        <IssuerSideFixedDeposits path="issuer-side-fixed-deposits/*" />
        <Equities path="equities/*" />
        <Disinvestments path="disinvestments/*" />
        <Withdrawals path="withdrawals/*" />
        <NotFound default />
      </Router>
    </div>
  );
};

export default AuthedApp;
