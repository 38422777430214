import { actionTypes } from "constants/index";

export const setIssuerSideFixedDeposits = issuerSideFixedDeposits => ({
  type: actionTypes.SET_ISFDS,
  payload: issuerSideFixedDeposits
});

export const unsetIssuerSideFixedDeposits = () => ({
  type: actionTypes.UNSET_ISFDS
});
