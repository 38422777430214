import React from "react";
import { Router } from "@reach/router";

import Scrolltop from "utils/scrolltop";
import { Navigation } from "components/Navigation";
import { Sider } from "components/Sider";
import Home from "./Home";
import CreateEquity from "./CreateEquity";
import EditEquity from "./EditEquity";
// const data = [];
// for (let i = 0; i < 7; i++) {
//   data.push({ name: `Route ${i}`, path: `/route${i}` });
// }

const routes = [
  { name: "Equities", path: "/equities" },
  { name: "Create Equity Instruction", path: "/equities/create" }
];

const Equities = () => {
  return (
    <div className="layout">
      <Navigation />
      <div className="flex" style={{ marginTop: "9rem" }}>
        <div>
          <Sider current="Equities" data={routes} />
        </div>
        <div style={{ marginLeft: "23rem", width: "100%" }}>
          <Router>
            <Scrolltop path="/">
              <Home path="/" />
              <CreateEquity path="create" />
              <EditEquity path="edit/:id" />
            </Scrolltop>
          </Router>
        </div>
      </div>
    </div>
  );
};

export default Equities;
