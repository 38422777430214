import React, { Component } from "react";

import Treasury from "./Treasury";

class CreateTreasury extends Component {
  render() {
    return <Treasury />;
  }
}

export default CreateTreasury;
