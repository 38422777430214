import React from "react";

import { Card } from "components/Card";

const data = [];
for (let i = 0; i < 7; i++) {
  data.push({ name: `Route ${i}`, path: `/route${i}` });
}

const EditFund = () => {
  return (
    <Card className="p-4rem flex-column">
      <div style={{ width: "70%" }}>
        <h4>Edit Fund</h4>

        <div className="row mt-2rem mb-2rem">
          <div className="column">
            <label className="mb-1rem">Fund Name</label>
            <input
              className="form-input form-input--lg"
              type="text"
              placeholder=""
              // value={email}
              // onChange={handleInput(setEmail)}
            />
          </div>
          <div className="column">
            <label className="mb-1rem">Fund Type</label>
            <select className="form-input form-input--lg">
              <option value="">Select fund type</option>
              <option value="1st">2</option>
              <option value="1st">3</option>
              <option value="1st">4</option>
              <option value="1st">5</option>
              <option value="1st">6</option>
            </select>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default EditFund;
