import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import * as R from "ramda";
import { parseISO, format, getUnixTime } from "date-fns";
import { Table, Modal } from "antd";
import { navigate } from "@reach/router";

import { formatNumber } from "utils/format";
import { handleApproval } from "utils/approvals";
import { INSTRUCTION_STATUS, APPROVAL_STATUS } from "constants/index";
import { setDisinvestments, unsetDisinvestments } from "actions/disinvestments";
// import { getInstructions, getApprovers } from "utils/get-thingy";

import { Card } from "components/Card";
import isPreTrade from "utils/is-pretrade";

const Home = ({
  token,
  funds,
  user,
  approvers,
  setDisinvestments,
  unsetDisinvestments,
  disinvestments,
  issuers
}) => {
  const [visible, setVisible] = React.useState(false);
  const [rejectionMessage, setRejectionMessage] = React.useState("");
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [selectedRecord, setSelectedRecord] = React.useState({});

  const tableColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => b.id - a.id,
      defaultSortOrder: "ascend"
    },
    {
      title: "Created On",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => (
        <span>{format(parseISO(record.createdAt), "dd/MM/yyyy")}</span>
      )
    },
    {
      title: "Fund",
      dataIndex: "fundId",
      key: "fundId",
      render: (text, record) => (
        <span
          className="pointer"
          title={funds.find(fund => fund.id === record.fundId).name}
          onClick={() => navigate(`disinvestments/edit/${record.id}`)}
        >
          {funds ? funds.find(fund => fund.id === record.fundId).name : null}
        </span>
      )
    },
    {
      title: "Principal Amount",
      dataIndex: "principalAmount",
      key: "principalAmount",
      render: (text, record) => (
        <span>{formatNumber(record.principalAmount)}</span>
      )
    },
    {
      title: "Rate",
      dataIndex: "rate",
      key: "rate"
    },
    {
      title: "Tenor",
      dataIndex: "tenor",
      render: (text, record) => <span>{record.tenor.count}</span>
    },
    {
      title: "Issuer",
      dataIndex: "issuer",
      render: (text, record) => (
        <span>
          {issuers
            ? issuers.find(issuer => issuer.id === record.issuerId).number
            : "--"}
        </span>
      )
    },
    {
      title: "PDF",
      dataIndex: "pdfFilename",
      key: "pdfFilename",
      render: (text, record) => (
        <>
          {record.pdfFilename ? (
            <a
              href={`${process.env.REACT_APP_BASE_URL}/pdf/${record.pdfFilename}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              View
            </a>
          ) : (
            <em className="text--muted">Not available</em>
          )}
        </>
      )
    },
    {
      title: "Signatories",
      dataIndex: "approvers",
      key: "approvers",
      render: (text, record) => (
        <>
          {record.approvers.length ? (
            record.approvers.map(approver => (
              <span
                key={approver.id}
                title={`${approver.firstName} ${approver.lastName}: ${
                  APPROVAL_STATUS[
                    record.groupedApprovals[approver.id][0].status
                  ].text
                }`}
                className={`mr-1rem badge ${
                  APPROVAL_STATUS[
                    record.groupedApprovals[approver.id][0].status
                  ].style
                }`}
              >
                {approver.firstName.charAt(0)}
                {approver.lastName.charAt(0)}
              </span>
            ))
          ) : (
            <span className="text--muted">--</span>
          )}
        </>
      )
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <React.Fragment>
          {isPreTrade(record.status) ? (
            <span className="badge badge--orange mr-1rem">Pre-Trade</span>
          ) : null}
          <span className={`badge ${INSTRUCTION_STATUS[record.status].style}`}>
            {INSTRUCTION_STATUS[record.status].text}
          </span>
        </React.Fragment>
      )
    }
  ];

  const approvalParams = {
    instructionIdURL: "disinvestmentId",
    token,
    user,
    approvalType: "APPROVAL"
  };

  const rejectionParams = {
    instructionIdURL: "disinvestmentId",
    token,
    user,
    approvalType: "REJECTION",
    rejectionMessage
  };

  const handleShowModal = record => {
    setSelectedRecord(record);
    setVisible(true);
  };

  const handleRejection = async (record, params) => {
    setConfirmLoading(true);

    await handleApproval(record, params);

    setConfirmLoading(false);
    setVisible(false);
  };

  const actions = {
    title: "Action",
    key: "action",
    render: (text, record) => (
      <span>
        <button
          type="button"
          onClick={() => handleApproval(record, approvalParams)}
          className="btn btn--green btn--small mb-1rem mr-1rem"
        >
          Verify {record.name}
        </button>
        <button
          type="button"
          onClick={() => {
            handleShowModal(record);
          }}
          className="btn btn--red btn--small"
        >
          Reject
        </button>
      </span>
    )
  };

  const columns =
    user.role === "APPROVER" ? [...tableColumns, actions] : tableColumns;

  const getDisinvestments = React.useCallback(async () => {
    // setLoading(true);

    const getApprovals = async id => {
      try {
        let res = await axios({
          url: `${process.env.REACT_APP_BASE_URL}/approval?$disinvestmentId=${id}&$sort[createdAt]=-1`,
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        const { data } = await res;
        return data;
      } catch (err) {
        console.error(err);
      }
    };

    const byApprover = R.groupBy(arr => {
      return arr.approverId;
    });
    try {
      let response = await axios({
        headers: {
          Authorization: `Bearer ${token}`
        },
        url: `${process.env.REACT_APP_BASE_URL}/disinvestment`,
        method: "GET"
      });
      let { data } = await response;

      // Group approvals by approver, then sort their approvals with latest updated at index 0
      let promises = await data.map(async disinvestment => {
        // Get approvals
        const approvals = await getApprovals(disinvestment.id);

        // Group approvals
        const groupedApprovals = await byApprover(approvals);

        // Sort each approver's approvals by updatedAt
        for (let approver in groupedApprovals) {
          groupedApprovals[approver].sort((a, b) => {
            return (
              getUnixTime(parseISO(b.updatedAt)) -
              getUnixTime(parseISO(a.updatedAt))
            );
          });
        }
        disinvestment.groupedApprovals = await groupedApprovals;

        let approverIds = R.keys(groupedApprovals);
        disinvestment.approvers = await approverIds
          .map(id =>
            approvers.find(approver => Number(approver.id) === Number(id))
          )
          .filter(function(el) {
            return el != null;
          });
        return disinvestment;
      });

      const withApprovals = await Promise.all(promises);

      setDisinvestments(withApprovals);
      // setLoading(false);
    } catch (err) {
      console.error(err);
      // setLoading(false);
    }
  }, [token, approvers, setDisinvestments]);

  React.useEffect(() => {
    // const params = {
    //   token: token,
    //   type: "disinvestment"
    // };
    // const getDisinvestments = async () => {
    //   const data = await getInstructions(params);
    //   const groupedData = await getApprovers(data);
    //   setDisinvestments(data);
    // };
    if (funds.length && disinvestments.loading) {
      getDisinvestments();
    }
  }, [token, funds, disinvestments, getDisinvestments]);

  const handleCloseModal = () => {
    setVisible(false);
  };

  return (
    <Card className="p-4rem flex-column">
      <span className="mb-2rem">All Disinvestment Intructions</span>
      <Table
        style={{ fontSize: "70%" }}
        rowKey={record => record.id}
        columns={columns}
        dataSource={disinvestments.instructions}
        loading={disinvestments.loading}
      />
      <Modal
        title="Reject Instruction"
        visible={visible}
        onOk={() => handleRejection(selectedRecord, rejectionParams)}
        onCancel={handleCloseModal}
        confirmLoading={confirmLoading}
      >
        <p className="mb-2rem">Please indicate a reason for rejection:</p>
        <textarea
          className="form-textarea--modal"
          name="rejectionMessage"
          value={rejectionMessage}
          onChange={event => setRejectionMessage(event.target.value)}
          cols="40"
          rows="5"
          autoFocus
        ></textarea>
      </Modal>
    </Card>
  );
};

export default connect(
  state => ({
    token: state.token,
    funds: state.funds,
    user: state.user,
    approvers: state.approvers,
    disinvestments: state.disinvestments,
    brokers: state.bankAccounts.filter(bankAccount => {
      return bankAccount.isBroker;
    }),
    counterparties: state.bankAccounts.filter(bankAccount => {
      return bankAccount.isCounterparty;
    }),
    issuers: state.bankAccounts.filter(bankAccount => {
      return bankAccount.isIssuer;
    })
  }),
  dispatch => ({
    setDisinvestments: disinvestments =>
      dispatch(setDisinvestments(disinvestments)),
    unsetDisinvestments: () => dispatch(unsetDisinvestments())
  })
)(Home);
