import React from "react";
import { Router } from "@reach/router";

import Scrolltop from "utils/scrolltop";
import { Navigation } from "components/Navigation";
import { Sider } from "components/Sider";
import Home from "./Home";
import CreateIssuerSideFixedDeposit from "./CreateIssuerSideFixedDeposit";
import EditIssuerSideFixedDeposit from "./EditIssuerSideFixedDeposit";

const routes = [
  { name: "Issuer Side Fixed Deposits", path: "/issuer-side-fixed-deposits" },
  {
    name: "Create Issuer Side Fixed Deposit",
    path: "/issuer-side-fixed-deposits/create"
  }
];

const IssuerSideFixedDeposits = () => {
  return (
    <div className="layout">
      <Navigation />
      <div className="flex" style={{ marginTop: "9rem" }}>
        <div>
          <Sider current="Fixed Deposits" data={routes} />
        </div>
        <div style={{ marginLeft: "23rem", width: "100%" }}>
          <Router>
            <Scrolltop path="/">
              <Home path="/" />
              <CreateIssuerSideFixedDeposit path="create" />
              <EditIssuerSideFixedDeposit path="edit/:id" />
            </Scrolltop>
          </Router>
        </div>
      </div>
    </div>
  );
};

export default IssuerSideFixedDeposits;
