export const actionTypes = {
  SET_TOKEN: "SET_TOKEN",
  UNSET_TOKEN: "UNSET_TOKEN",
  SET_FUNDS: "SET_FUNDS",
  SET_CSD: "SET_CSD",
  SET_APPROVERS: "SET_APPROVERS",
  SET_USER: "SET_USER",
  SET_USERS: "SET_USERS",
  SET_BANKS: "SET_BANKS",
  SET_BROKERS: "SET_BROKERS",
  SET_MAILINGLISTS: "SET_MAILINGLISTS",

  // Equities
  SET_EQUITIES: "SET_EQUITIES",
  UNSET_EQUITIES: "UNSET_EQUITIES",

  // Treasuries
  SET_TREASURIES: "SET_TREASURIES",
  UNSET_TREASURIES: "UNSET_TREASURIES",

  // Disinvestments
  SET_DISINVESTMENTS: "SET_DISINVESTMENTS",
  UNSET_DISINVESTMENTS: "UNSET_DISINVESTMENTS",

  // Fixed deposits
  SET_FDS: "SET_FDS",
  UNSET_FDS: "UNSET_FDS",

  // Issuer-side fixed deposits
  SET_ISFDS: "SET_ISFDS",
  UNSET_ISFDS: "UNSET_ISFDS"
};

export const INSTRUCTION_STATUS = {
  NEW: { style: "badge--orange", text: "New" },
  PENDING_INTERNAL_PRE_APPROVAL: {
    style: "badge--orange",
    text: "Pending Int."
  },
  INTERNAL_PRE_APPROVAL_REJECTED: {
    style: "badge--red",
    text: "Int. Rejected"
  },
  SENDING_PRE_APPROVAL_FAILED: {
    style: "badge--red",
    text: "P.A Error"
  },
  PENDING_EXTERNAL_PRE_APPROVAL: {
    style: "badge--orange",
    text: "Pending Ext."
  },
  EXTERNAL_PRE_APPROVAL_REJECTED: {
    style: "badge--red",
    text: "Ext. Rejected"
  },
  PENDING_SETTLEMENT_UPDATE: {
    style: "badge--orange",
    text: "Pending Smt. Upd."
  },
  PENDING_SETTLEMENT_APPROVAL: {
    style: "badge--orange",
    text: "Pending Smt. App."
  },
  SETTLEMENT_APPROVED: { style: "badge--green", text: "Smt. Approved" },
  SETTLEMENT_REJECTED: { style: "badge--red", text: "Smt. Rejected" },
  SENT: { style: "badge--green", text: "Sent" },
  CANCELLED: { style: "badge--red", text: "Cancelled" },
  SENDING_FAILED: { style: "badge--red", text: "Failed" }
};

export const APPROVAL_STATUS = {
  PENDING: { style: "badge--orange", text: "Pending" },
  APPROVED: { style: "badge--green", text: "Approved" },
  REJECTED: { style: "badge--red", text: "Rejected" }
};

export const TRANSACTION_TYPE = {
  PURCHASE: "Purchase",
  SALE: "Sale"
};

export const PROCESS_TYPE = {
  PRIMARY: "Primary",
  SECONDARY: "Secondary",
  RETAP: "Retap"
};

export const FD_PROCESS_TYPE = {
  NEW_PLACEMENT: "New Placement",
  DISINVESTMENT: "Disinvestment",
  ROLLOVER: "Rollover",
  CALLABLE: "Callable"
};

export const ISFD_PROCESS_TYPE = {
  NEW_PLACEMENT: "New Placement",
  INTEREST: "Interest",
  MATURITY: "Maturity",
  ROLLOVER: "Rollover"
};

export const E_PROCESS_TYPE = {
  PRIMARY: "IPO - Primary",
  SECONDARY: "Secondary"
};

export const USER_ROLE = {
  USER: "User",
  ADMIN: "Admin",
  APPROVER: "Approver"
};

export const PERIOD = {
  DAYS: "Days",
  WEEKS: "Weeks",
  MONTHS: "Months",
  YEARS: "Years"
};
