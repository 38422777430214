import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";

import { token } from "reducers/authUser";
import { funds } from "reducers/funds";
import { approvers, user, users } from "reducers/users";
import { csdAccounts, bankAccounts } from "reducers/accounts";
import {
  equities,
  treasuries,
  disinvestments,
  fixedDeposits,
  issuerSideFixedDeposits
} from "reducers/instructions";
// import { treasuries } from "reducers/treasuries";
// import { disinvestments } from "reducers/disinvestments";

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducer = combineReducers({
  token,
  funds,
  approvers,
  user,
  users,
  csdAccounts,
  bankAccounts,
  equities,
  treasuries,
  disinvestments,
  fixedDeposits,
  issuerSideFixedDeposits
});

const store = createStore(reducer, composeEnhancer(applyMiddleware(thunk)));

export default store;
