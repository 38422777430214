import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import * as R from "ramda";
import { parseISO, format, getUnixTime } from "date-fns";
import { Table, Modal, Button, Input, Icon } from "antd";
import { navigate } from "@reach/router";

import { handleApproval } from "utils/approvals";
import isPreTrade from "utils/is-pretrade";
import {
  INSTRUCTION_STATUS,
  APPROVAL_STATUS,
  PROCESS_TYPE,
  TRANSACTION_TYPE
} from "constants/index";
import { setTreasuries, unsetTreasuries } from "actions/treasuries";

import { Card } from "components/Card";

const Home = ({
  token,
  funds,
  user,
  approvers,
  setTreasuries,
  unsetTreasuries,
  treasuries,
  counterparties,
  brokers
}) => {
  const [visible, setVisible] = React.useState(false);
  const [rejectionMessage, setRejectionMessage] = React.useState("");
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [selectedRecord, setSelectedRecord] = React.useState({});

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          // ref={node => {
          //   searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        // setTimeout(() => searchInput.select());
      }
    }
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();

    // setSearchText(selectedKeys[0]);
    // setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    // setSearchedColumn("");
  };

  const tableColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => b.id - a.id,
      defaultSortOrder: "ascend"
    },
    {
      title: "Created On",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => (
        <span>{format(parseISO(record.createdAt), "dd/MM/yyyy")}</span>
      )
    },
    {
      title: "Fund",
      dataIndex: "fundId",
      key: "fundId",
      render: (text, record) => (
        <span
          className="pointer"
          title={funds.find(fund => fund.id === record.fundId).name}
          onClick={() => navigate(`treasuries/edit/${record.id}`)}
        >
          {funds ? funds.find(fund => fund.id === record.fundId).name : null}
        </span>
      )
    },
    {
      title: "Process Type",
      dataIndex: "processType",
      key: "processType",
      ...getColumnSearchProps("processType"),
      render: (text, record) => (
        <span className={PROCESS_TYPE[record.processType]}>
          {TRANSACTION_TYPE[record.transactionType]}:{" "}
          {PROCESS_TYPE[record.processType]}
        </span>
      )
    },
    {
      title: "Counter Party",
      dataIndex: "counterparty",
      key: "counterparty"
    },
    {
      title: "PDF",
      dataIndex: "pdfFilename",
      key: "pdfFilename",
      render: (text, record) => (
        <>
          {record.pdfFilename ? (
            <a
              href={`${process.env.REACT_APP_BASE_URL}/pdf/${record.pdfFilename}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              View
            </a>
          ) : (
            <em className="text--muted">Not available</em>
          )}
        </>
      )
    },
    {
      title: "Signatories",
      dataIndex: "approvers",
      key: "approvers",
      render: (text, record) => (
        <>
          {record.approvers.length ? (
            record.approvers.map(approver => (
              <span
                key={approver.id}
                title={`${approver.firstName} ${approver.lastName}: ${
                  APPROVAL_STATUS[
                    record.groupedApprovals[approver.id][0].status
                  ].text
                }`}
                className={`mr-1rem badge ${
                  APPROVAL_STATUS[
                    record.groupedApprovals[approver.id][0].status
                  ].style
                }`}
              >
                {approver.firstName.charAt(0)}
                {approver.lastName.charAt(0)}
              </span>
            ))
          ) : (
            <span className="text--muted">--</span>
          )}
        </>
      )
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <React.Fragment>
          {isPreTrade(record.status) ? (
            <span className="badge badge--orange mr-1rem">Pre-Trade</span>
          ) : null}
          <span className={`badge ${INSTRUCTION_STATUS[record.status].style}`}>
            {INSTRUCTION_STATUS[record.status].text}
          </span>
        </React.Fragment>
      )
    }
  ];

  const approvalParams = {
    instructionIdURL: "governmentSecurityId",
    token,
    user,
    approvalType: "APPROVAL"
  };

  const rejectionParams = {
    instructionIdURL: "governmentSecurityId",
    token,
    user,
    approvalType: "REJECTION",
    rejectionMessage
  };

  const handleShowModal = record => {
    setSelectedRecord(record);
    setVisible(true);
  };

  const handleRejection = async (record, params) => {
    setConfirmLoading(true);

    await handleApproval(record, params);

    setConfirmLoading(false);
    setVisible(false);
  };

  const actions = {
    title: "Action",
    key: "action",
    render: (text, record) => (
      <span>
        <button
          type="button"
          onClick={() => handleApproval(record, approvalParams)}
          className="btn btn--green btn--small mb-1rem mr-1rem"
        >
          Verify {record.name}
        </button>
        <button
          type="button"
          onClick={() => {
            handleShowModal(record);
          }}
          className="btn btn--red btn--small"
        >
          Reject
        </button>
      </span>
    )
  };

  const columns =
    user.role === "APPROVER" ? [...tableColumns, actions] : tableColumns;

  // TODO: Might be a cleaner way to get latest updated approval
  // const latestUpdated = arr => {
  //   arr.reduce((max, next) =>
  //     getUnixTime(parseISO(max.updatedAt)) >
  //     getUnixTime(parseISO(next.updatedAt))
  //       ? max
  //       : next
  //   );
  // };

  const getTreasuries = React.useCallback(async () => {
    // setLoading(true);

    const getApprovals = async id => {
      try {
        let res = await axios({
          url: `${process.env.REACT_APP_BASE_URL}/approval?governmentSecurityId=${id}&$sort[createdAt]=-1`,
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        const { data } = await res;
        return data;
      } catch (err) {
        console.error(err);
      }
    };

    const byApprover = R.groupBy(arr => {
      return arr.approverId;
    });
    try {
      let response = await axios({
        headers: {
          Authorization: `Bearer ${token}`
        },
        url: `${process.env.REACT_APP_BASE_URL}/government-security`,
        method: "GET"
      });
      let { data } = await response;

      // Group approvals by approver, then sort their approvals with latest updated at index 0
      let promises = await data.map(async treasury => {
        // Get approvals
        const approvals = await getApprovals(treasury.id);

        // Group approvals
        const groupedApprovals = await byApprover(approvals);

        // Sort each approver's approvals by updatedAt
        for (let approver in groupedApprovals) {
          groupedApprovals[approver].sort((a, b) => {
            return (
              getUnixTime(parseISO(b.updatedAt)) -
              getUnixTime(parseISO(a.updatedAt))
            );
          });
        }
        treasury.groupedApprovals = await groupedApprovals;

        let approverIds = R.keys(groupedApprovals);
        treasury.approvers = await approverIds
          .map(id =>
            approvers.find(approver => Number(approver.id) === Number(id))
          )
          .filter(function(el) {
            return el != null;
          });
        return treasury;
      });

      // let getFundName = await data.map(async treasury => {
      //   treasury.fundName = funds.find(
      //     fund => fund.id === treasury.fundId
      //   ).name;
      //   return treasury;
      // });

      // const withApprovals = await Promise.all([promises, getFundName]);
      const withApprovals = await Promise.all(promises);
      setTreasuries(withApprovals);
      // debugger;
      // setLoading(false);
    } catch (err) {
      console.error(err);
      // setLoading(false);
    }
    // }, [funds, token, approvers, setTreasuries]);
  }, [token, approvers, setTreasuries]);

  React.useEffect(() => {
    if (funds.length && treasuries.loading) {
      getTreasuries();
    }
  }, [token, funds, getTreasuries, treasuries]);

  const handleCloseModal = () => {
    setVisible(false);
  };

  return (
    <Card className="p-4rem flex-column">
      <span className="mb-2rem">All Treasury Intructions</span>
      <Table
        style={{ fontSize: "70%" }}
        rowKey={record => record.id}
        columns={columns}
        dataSource={treasuries.instructions}
        loading={treasuries.loading}
      />
      <Modal
        title="Reject Instruction"
        visible={visible}
        onOk={() => handleRejection(selectedRecord, rejectionParams)}
        onCancel={handleCloseModal}
        confirmLoading={confirmLoading}
      >
        <p className="mb-2rem">Please indicate a reason for rejection:</p>
        <textarea
          className="form-textarea--modal"
          name="rejectionMessage"
          value={rejectionMessage}
          onChange={event => setRejectionMessage(event.target.value)}
          cols="40"
          rows="5"
          autoFocus
        ></textarea>
      </Modal>
    </Card>
  );
};

export default connect(
  state => ({
    token: state.token,
    funds: state.funds,
    user: state.user,
    approvers: state.approvers,
    treasuries: state.treasuries,
    brokers: state.bankAccounts.filter(bankAccount => {
      return bankAccount.isBroker;
    }),
    counterparties: state.bankAccounts.filter(bankAccount => {
      return bankAccount.isCounterparty;
    })
  }),
  dispatch => ({
    setTreasuries: treasuries => dispatch(setTreasuries(treasuries)),
    unsetTreasuries: () => dispatch(unsetTreasuries())
  })
)(Home);
