import { actionTypes } from "constants/index";

export const setCSD = csdAccounts => ({
  type: actionTypes.SET_CSD,
  payload: csdAccounts
});

export const setBanks = bankAccounts => ({
  type: actionTypes.SET_BANKS,
  payload: bankAccounts
});
