const isPreTrade = status => {
  if (
    status === "PENDING_INTERNAL_PRE_APPROVAL" ||
    status === "INTERNAL_PRE_APPROVAL_REJECTED" ||
    status === "PENDING_EXTERNAL_PRE_APPROVAL" ||
    status === "EXTERNAL_PRE_APPROVAL_REJECTED"
  ) {
    return true;
  } else return false;
};

export default isPreTrade;
