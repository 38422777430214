import React from "react";
import cx from "classnames";

const Card = props => {
  const { className: classname, style } = props;
  return (
    <div className={cx("card", classname)} style={style}>
      {props.children}
    </div>
  );
};

export default Card;
