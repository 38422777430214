/**
 * Formats a number. Formats non-integer numbers to two decimal places
 *
 * @param {number} number - The number to format
 */
export const formatNumber = value => {
  const number = Number(value);
  if (Number.isInteger(number)) {
    return new Intl.NumberFormat("en-US", {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0
    }).format(number);
  } else {
    return new Intl.NumberFormat("en-US", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    }).format(number);
  }
};

/**
 * Truncates a string of characters.
 *
 * @param {string} string - The text string to be truncated.
 * @param {length} length - The number of characters to truncate beyond. Defaults to 15.
 */
export const truncateString = (string, length = 15) => {
  if (string.length < length) {
    return string;
  }
  return string.substring(0, length) + "...";
};
