import axios from "axios";
// import * as R from "ramda";
// import { getUnixTime, parseISO } from "date-fns";
import * as jwt from "jsonwebtoken";

import store from "store";
import { setCSD, setBanks } from "actions/accounts";
import { setApprovers, setUser, setUsers } from "actions/users";
import { fetchFunds } from "actions/funds";

// const { token } = store.getState();

export const getInitialData = token => {
  getUser(token);
  fetchFunds(token);
  getApprovers(token);
  getCSD(token);
  getBanks(token);
  getUsers(token);
};

export const getBanks = async token => {
  let response = await axios({
    headers: {
      Authorization: `Bearer ${token}`
    },
    url: `${process.env.REACT_APP_BASE_URL}/bank-account`,
    method: "GET"
  });
  let { data } = response;
  data.reverse();
  store.dispatch(setBanks(data));
};

export const getApprovers = async token => {
  let response = await axios({
    headers: {
      Authorization: `Bearer ${token}`
    },
    url: `${process.env.REACT_APP_BASE_URL}/user?role=APPROVER`,
    method: "GET"
  });
  let { data } = response;
  store.dispatch(setApprovers(data));
};

export const getUsers = async token => {
  let res = await axios({
    headers: {
      Authorization: `Bearer ${token}`
    },
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL}/user`
  });
  let { data } = res;
  store.dispatch(setUsers(data));
};

export const getUser = async token => {
  let { user } = store.getState();
  if (!user.id) {
    let { sub: userId } = jwt.decode(token);
    let response = await axios({
      headers: {
        Authorization: `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_BASE_URL}/user/${userId}`,
      method: "GET"
    });
    let { data } = response;
    store.dispatch(setUser(data));
  }
};

export const getCSD = async token => {
  let response = await axios({
    headers: {
      Authorization: `Bearer ${token}`
    },
    url: `${process.env.REACT_APP_BASE_URL}/csd-account`,
    method: "GET"
  });
  let { data } = response;
  store.dispatch(setCSD(data));
};

export async function getInstructions(params) {
  const { token, type } = params;
  try {
    let response = await axios({
      headers: {
        Authorization: `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_BASE_URL}/${type}?_relations=approvals`,
      method: "GET"
    });
    let { data } = await response;
    return data;
  } catch (err) {
    console.error(err);
  }
}

// export function getApprovers(instructions) {
//   const byApprover = R.groupBy(arr => {
//     return arr.approverId;
//   });
//   const grouped = instructions.map(instruction => {
//     return byApprover(instruction.approvals);
//   });

//   const nextGrouped = grouped.map(approval => {
//     // debugger;
//     // Sort each approver's approvals by updatedAt
//     for (let approver in approval) {
//       approval[approver].sort((a, b) => {
//         return (
//           getUnixTime(parseISO(b.updatedAt)) -
//           getUnixTime(parseISO(a.updatedAt))
//         );
//       });
//     }
//   });

//   // return grouped;
// }
