import React from "react";
import { Location } from "@reach/router";

import Treasury from "./Treasury";

const EditTreasury = ({ id }) => {
  return (
    <Location>
      {({ location }) => <Treasury location={location} editMode id={id} />}
    </Location>
  );
};

export default EditTreasury;
