import axios from "axios";

import { actionTypes } from "constants/index";
import { sortByName } from "utils/sort";

export const setFunds = funds => ({
  type: actionTypes.SET_FUNDS,
  payload: funds
});

// const { token } = store.getState();

export function fetchFunds(token) {
  return async dispatch => {
    try {
      let response = await axios({
        headers: {
          Authorization: `Bearer ${token}`
        },
        url: `${process.env.REACT_APP_BASE_URL}/fund`,
        method: "GET"
      });
      let { data } = await response;
      const sortedData = data.sort(sortByName);
      await dispatch(setFunds(sortedData));
    } catch (err) {
      console.error(err);
    }
  };
}
