import React from "react";
import { Router } from "@reach/router";

import Scrolltop from "utils/scrolltop";
import { Navigation } from "components/Navigation";
import { Sider } from "components/Sider";
import Home from "./Home";
import EditUser from "./EditUser";
import EditFund from "./EditFund";
import CreateFund from "./CreateFund";
import CreateUser from "./CreateUser";

const routes = [
  { name: "Admin", path: "/admin" },
  { name: "Create User", path: "/admin/create/user" },
  { name: "Create Fund", path: "/admin/create/fund" }
];

const Admin = () => {
  return (
    <div className="layout">
      <Navigation />
      <div className="flex" style={{ marginTop: "9rem" }}>
        <div>
          <Sider current="Admin" data={routes} />
        </div>
        <div style={{ marginLeft: "23rem", width: "100%" }}>
          <Router>
            <Scrolltop path="/">
              <Home path="/" />
              <EditUser path="edit/user/:id" />
              <EditFund path="edit/fund/:id" />
              <CreateUser path="create/user" />
              <CreateFund path="create/fund" />
            </Scrolltop>
          </Router>
        </div>
      </div>
    </div>
  );
};

export default Admin;
