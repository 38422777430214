import { actionTypes } from "constants/index";

export const setTreasuries = payload => ({
  type: actionTypes.SET_TREASURIES,
  payload
});

export const unsetTreasuries = () => ({
  type: actionTypes.UNSET_TREASURIES
});
