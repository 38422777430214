import { actionTypes } from "constants/index";

export const setDisinvestments = disinvestments => ({
  type: actionTypes.SET_DISINVESTMENTS,
  payload: disinvestments
});

export const unsetDisinvestments = () => ({
  type: actionTypes.UNSET_DISINVESTMENTS
});
