import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import * as R from "ramda";
import { parseISO, getUnixTime } from "date-fns";
import { Table, Modal } from "antd";
import { toast } from "react-toastify";
import { navigate } from "@reach/router";

import { formatNumber } from "utils/format";
import { handleApproval } from "utils/approvals";
import { INSTRUCTION_STATUS, APPROVAL_STATUS } from "constants/index";

import { Card } from "components/Card";
import isPreTrade from "utils/is-pretrade";

const Home = ({ token, funds, user, approvers }) => {
  const [withdrawals, setWithdrawals] = React.useState([]);
  const [rejectionMessage, setRejectionMessage] = React.useState("");
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [selectedRecord, setSelectedRecord] = React.useState({});

  const tableColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => b.id - a.id,
      defaultSortOrder: "ascend"
    },
    {
      title: "Fund",
      dataIndex: "fundId",
      key: "fundId",
      render: (text, record) => (
        <span
          className="pointer"
          title={funds.find(fund => fund.id === record.fundId).name}
          onClick={() => {
            navigate(`withdrawals/edit/${record.id}`);
          }}
        >
          {funds ? funds.find(fund => fund.id === record.fundId).name : null}
        </span>
      )
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => <span>{formatNumber(record.amount)}</span>
    },
    {
      title: "Note",
      dataIndex: "note",
      key: "note"
    },
    {
      title: "Signatories",
      dataIndex: "approvers",
      key: "approvers",
      render: (text, record) => (
        <>
          {record.approvers.length ? (
            record.approvers.map(approver => (
              <span
                key={approver.id}
                title={`${approver.firstName} ${approver.lastName}: ${
                  APPROVAL_STATUS[
                    record.groupedApprovals[approver.id][0].status
                  ].text
                }`}
                className={`mr-1rem badge ${
                  APPROVAL_STATUS[
                    record.groupedApprovals[approver.id][0].status
                  ].style
                }`}
              >
                {approver.firstName.charAt(0)}
                {approver.lastName.charAt(0)}
              </span>
            ))
          ) : (
            <span className="text--muted">--</span>
          )}
        </>
      )
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <React.Fragment>
          {isPreTrade(record.status) ? (
            <span className="badge badge--orange mr-1rem">Pre-Trade</span>
          ) : null}
          <span className={`badge ${INSTRUCTION_STATUS[record.status].style}`}>
            {INSTRUCTION_STATUS[record.status].text}
          </span>
        </React.Fragment>
      )
    }
  ];

  const params = {
    instructionIdURL: "withdrawalId",
    token,
    user,
    approvalType: "APPROVAL"
  };

  const actions = {
    title: "Action",
    key: "action",
    render: (text, record) => (
      <span>
        <button
          type="button"
          onClick={() => handleApproval(record, params)}
          className="btn btn--green btn--small mr-1rem mb-1rem"
        >
          Verify {record.name}
        </button>
        <button
          type="button"
          onClick={() => {
            setVisible(true);
            setSelectedRecord(record);
          }}
          className="btn btn--red btn--small"
        >
          Reject
        </button>
      </span>
    )
  };

  const columns =
    user.role === "APPROVER" ? [...tableColumns, actions] : tableColumns;

  const getWithdrawals = React.useCallback(async () => {
    setLoading(true);

    const getApprovals = async id => {
      try {
        let res = await axios({
          url: `${process.env.REACT_APP_BASE_URL}/approval?withdrawalId=${id}`,
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        const { data } = await res;
        return data;
      } catch (err) {
        console.error(err);
      }
    };

    const byApprover = R.groupBy(arr => {
      return arr.approverId;
    });
    try {
      let response = await axios({
        headers: {
          Authorization: `Bearer ${token}`
        },
        url: `${process.env.REACT_APP_BASE_URL}/withdrawal`,
        method: "GET"
      });
      let { data } = await response;

      // Group approvals by approver, then sort their approvals with latest updated at index 0
      let promises = await data.map(async withdrawal => {
        // Get approvals
        const approvals = await getApprovals(withdrawal.id);

        // Group approvals
        const groupedApprovals = await byApprover(approvals);

        // Sort each approver's approvals by updatedAt
        for (let approver in groupedApprovals) {
          groupedApprovals[approver].sort((a, b) => {
            return (
              getUnixTime(parseISO(b.updatedAt)) -
              getUnixTime(parseISO(a.updatedAt))
            );
          });
        }
        withdrawal.groupedApprovals = await groupedApprovals;

        let approverIds = R.keys(groupedApprovals);
        withdrawal.approvers = await approverIds
          .map(id =>
            approvers.find(approver => Number(approver.id) === Number(id))
          )
          .filter(function(el) {
            return el != null;
          });
        return withdrawal;
      });

      const withApprovals = await Promise.all(promises);

      setWithdrawals(withApprovals);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  }, [token, approvers]);

  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      getWithdrawals();
    }
    return () => (isSubscribed = false);
  }, [token, funds, getWithdrawals]);

  const handleRejection = async record => {
    setConfirmLoading(true);
    try {
      let res = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/approval?withdrawalId=${record.id}`,
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      const approvals = await res.data;
      const approval = approvals.find(
        approval => approval.approverId === user.id
      );

      if (res.status > 199 && res.status < 299) {
        try {
          await axios({
            method: "PATCH",
            url: `${process.env.REACT_APP_BASE_URL}/approval/${approval.id}`,
            data: {
              status: "REJECTED",
              rejectionMessage
            },
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          setConfirmLoading(false);
          setVisible(false);
          toast.success("Instruction rejected");
          await getWithdrawals();
        } catch (err) {
          toast.error("Something went wrong. Please try again.");
          console.error(err);
          setConfirmLoading(false);
          setVisible(false);
        }
      }
    } catch (err) {
      toast.error("Something went wrong. Please try again.");
      setConfirmLoading(false);
      console.error(err);
    }
  };

  return (
    <Card className="p-4rem flex-column">
      <span className="mb-2rem">Withdrawals Home</span>
      <Table
        style={{ fontSize: "70%" }}
        rowKey={record => record.id}
        columns={columns}
        dataSource={withdrawals}
        loading={loading}
      />
      <Modal
        title="Basic Modal"
        visible={visible}
        onOk={() => handleRejection(selectedRecord)}
        onCancel={() => setVisible(false)}
        confirmLoading={confirmLoading}
      >
        <p className="mb-2rem">Please indicate a reason for rejection:</p>
        <textarea
          className="form-textarea--modal"
          name="rejectionMessage"
          value={rejectionMessage}
          onChange={event => setRejectionMessage(event.target.value)}
          cols="40"
          rows="5"
          autoFocus
        ></textarea>
      </Modal>
    </Card>
  );
};

export default connect(state => ({
  token: state.token,
  funds: state.funds,
  user: state.user,
  approvers: state.approvers
}))(Home);
