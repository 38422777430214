import { actionTypes } from "constants/index";

export const setApprovers = approvers => ({
  type: actionTypes.SET_APPROVERS,
  payload: approvers
});

export const setUser = user => ({
  type: actionTypes.SET_USER,
  payload: user
});

export const setUsers = users => ({
  type: actionTypes.SET_USERS,
  payload: users
});
