import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import { Redirect } from "@reach/router";
import { Button } from "antd";

import store from "store";
import { setToken } from "actions/authUser";
import { setUser } from "actions/users";
import useForm from "hooks/useForm";

import logo from "images/asset-mgt.png";

const Login = ({ setToken, token, location }) => {
  const { values, handleInput, handleSubmit, isSubmitting } = useForm(onSubmit);
  const [message, setMessage] = React.useState("");

  async function onSubmit() {
    const url = `${process.env.REACT_APP_BASE_URL}/authentication`;
    const data = {
      strategy: "local",
      email: values.email,
      password: values.password
    };

    try {
      let res = await axios({
        method: "POST",
        url,
        data,
        headers: {
          "Content-Type": "application/json"
        }
      });

      const { accessToken, user } = await res.data;

      if (accessToken) {
        setToken(accessToken);
        store.dispatch(setUser(user));
        // navigate(location.pathname);
        // TODO: Currently, navigation to previous path throws an error if async resources aren't loaded. Find a way around this
        // navigate("/");
      } else {
        setMessage("Login failed, please try again.");
      }
    } catch (err) {
      console.error(err);
      setMessage("Login failed, please try again");
    }
  }

  if (token) {
    return <Redirect path="/" />;
  }

  return (
    <div className="layout">
      <div className="flex center-content">
        <div className="align-vertical flex-column card inline-block mt-10rem center-content">
          <img className="logo" src={logo} alt="IC Asset Managers" />
          <form onSubmit={handleSubmit} style={{ minWidth: "35rem" }}>
            <h3 className="align--center">Document Management System</h3>
            <div className="flex-column p-1rem">
              <input
                className="form-input form-control"
                type="text"
                placeholder="Email address"
                name="email"
                value={values.email}
                required
                onChange={handleInput}
              />
            </div>
            <div className="flex-column p-1rem">
              <input
                className="form-input form-control"
                type="password"
                placeholder="Password"
                name="password"
                value={values.password}
                required
                onChange={handleInput}
              />
            </div>
            <div className="p-1rem center-content">
              <Button
                className="form-control"
                type="primary"
                htmlType="submit"
                loading={isSubmitting}
              >
                Log In
              </Button>
            </div>
            <p className="align--center">
              {message ? <span style={{ color: "red" }}>{message}</span> : null}
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default connect(
  state => ({
    token: state.token
  }),
  dispatch => ({
    setToken: token => dispatch(setToken(token))
    // setUser: user => dispatch(setUser(user))
  })
)(Login);
