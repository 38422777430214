import React from "react";
import { connect } from "react-redux";

import { Sider } from "components/Sider";
import { Card } from "components/Card";
import { Navigation } from "components/Navigation";
// import axios from "axios";
import { navigate } from "@reach/router";

const routes = [{ name: "", path: "" }];

export const Home = ({ token }) => {
  // const handleLogout = () => {
  //   props.unsetToken();
  // };
  // const [cashBalances, setCashBalances] = React.useState([]);
  React.useEffect(() => {
    navigate("/treasuries");
  }, []);
  // React.useEffect(() => {
  //   const getCashBalances = async () => {
  //     let response = await axios({
  //       headers: {
  //         Authorization: `Bearer ${token}`
  //       },
  //       url: `${process.env.REACT_APP_BASE_URL}/cash-balance`,
  //       method: "GET"
  //     });
  //     let { data } = await response;
  //     setCashBalances(data);
  //   };
  //   getCashBalances();
  // }, [token]);

  return (
    <div className="layout">
      <Navigation />
      <div className="flex" style={{ marginTop: "9rem" }}>
        <div>
          <Sider current="Home" data={routes} />
        </div>
        <div style={{ marginLeft: "23rem", width: "100%" }}>
          <Card className="p-4rem flex-column">
            {/* Home
            {cashBalances
              ? cashBalances.map(cashBalance => (
                  <div key={cashBalance.id}>{cashBalance.amount}</div>
                ))
              : null} */}
          </Card>
        </div>
      </div>
    </div>
  );
};

export default connect(state => ({
  token: state.token
}))(Home);
