import { actionTypes } from "constants/index";

export const setFixedDeposits = fixedDeposits => ({
  type: actionTypes.SET_FDS,
  payload: fixedDeposits
});

export const unsetFixedDeposits = () => ({
  type: actionTypes.UNSET_FDS
});
