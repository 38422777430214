import React from "react";
import { connect } from "react-redux";
import { Link } from "@reach/router";
import { Button } from "antd";

import { USER_ROLE } from "constants/index";

import { Card } from "components/Card";

const Home = ({ funds, users }) => {
  return (
    <Card className="p-4rem flex-column">
      <div style={{ width: "70%", fontSize: "75%" }}>
        <h4 className="mb-2rem">Manage Funds</h4>
        {funds
          ? funds.map(fund => (
              <div key={fund.id} className="row mt-2rem mb-2rem">
                <div className="column underline truncate">{fund.name}</div>
                <div className="column underline">{fund.trustee}</div>
                <Link className="underline" to={`edit/fund/${fund.id}`}>
                  Edit
                </Link>
              </div>
            ))
          : null}

        <Button type="primary">
          <Link to="/">Add new fund</Link>
        </Button>

        <hr className="mt-4rem mb-4rem" />

        <h4>Manage Users</h4>

        {users
          ? users.map(user => (
              <div key={user.id} className="row mt-2rem mb-2rem">
                <div className="column underline">{user.email}</div>
                <div className="column underline">{USER_ROLE[user.role]}</div>

                <Link className="underline" to={`edit/user/${user.id}`}>
                  Edit
                </Link>
              </div>
            ))
          : null}

        <Button type="primary">
          <Link to="/">Add new user</Link>
        </Button>
      </div>
    </Card>
  );
};

export default connect(state => ({
  funds: state.funds,
  users: state.users
}))(Home);
