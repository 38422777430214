import React from "react";
import { Router } from "@reach/router";

import Scrolltop from "utils/scrolltop";
import { Navigation } from "components/Navigation";
import { Sider } from "components/Sider";
import Home from "./Home";
import CreateFixedDeposit from "./CreateFixedDeposit";
import EditFixedDeposit from "./EditFixedDeposit";

const routes = [
  { name: "Fixed Deposits", path: "/fixed-deposits" },
  { name: "Create Fixed Deposit", path: "/fixed-deposits/create" }
];

const FixedDeposits = () => {
  return (
    <div className="layout">
      <Navigation />
      <div className="flex" style={{ marginTop: "9rem" }}>
        <div>
          <Sider current="Fixed Deposits" data={routes} />
        </div>
        <div style={{ marginLeft: "23rem", width: "100%" }}>
          <Router>
            <Scrolltop path="/">
              <Home path="/" />
              <CreateFixedDeposit path="create" />
              <EditFixedDeposit path="edit/:id" />
            </Scrolltop>
          </Router>
        </div>
      </div>
    </div>
  );
};

export default FixedDeposits;
