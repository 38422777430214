import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import { Button } from "antd";
import { toast } from "react-toastify";

import { USER_ROLE } from "constants/index";

import { Card } from "components/Card";

const EditUser = ({ id, token }) => {
  const [user, setUser] = React.useState({});
  const [signature, setSignature] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [isSubmitting, setIsSubmitting] = React.useState();

  React.useEffect(() => {
    const getUser = async () => {
      let result = await axios({
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        },
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/user/${id}`
      });
      const { data } = result;
      setUser(data);
      setLoading(false);
    };
    getUser();
  }, [token, id]);

  const handleInput = setValue => event => {
    const { name, value } = event.target;
    setValue({ ...user, [name]: value });
  };

  // TODO: Add image constraints
  const handleImageUpload = event => {
    let file = event.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function() {
      setSignature(reader.result);
    };
  };

  const handleSubmit = async () => {
    const userData = {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      role: user.role,
      phoneNumber: user.phoneNumber
    };
    try {
      setIsSubmitting(true);
      let result = await axios({
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        method: "PATCH",
        url: `${process.env.REACT_APP_BASE_URL}/user/${id}`,
        data: {
          ...userData,
          signature
        }
      });
      let { data } = result;
      setIsSubmitting(false);
      setUser(data);
      toast.success("User updated");
    } catch (err) {
      console.error(err);
      setIsSubmitting(false);
      toast.error("Something went wrong. Please try again");
    }
  };

  return (
    <Card className="p-4rem flex-column">
      {!loading ? (
        <div style={{ width: "70%" }}>
          <h4>Edit User</h4>

          <div className="row mt-2rem mb-2rem">
            <div className="column">
              <label className="mb-1rem">First Name</label>
              <input
                className="form-input form-input--lg"
                type="text"
                placeholder=""
                name="firstName"
                value={user.firstName}
                onChange={handleInput(setUser)}
              />
            </div>
            <div className="column">
              <label className="mb-1rem">Last Name</label>
              <input
                className="form-input form-input--lg"
                type="text"
                placeholder=""
                name="lastName"
                value={user.lastName}
                onChange={handleInput(setUser)}
              />
            </div>
          </div>

          <div className="row mt-2rem mb-2rem">
            <div className="column">
              <label className="mb-1rem">User Email</label>
              <input
                className="form-input form-input--lg"
                type="text"
                placeholder=""
                name="email"
                value={user.email}
                onChange={handleInput(setUser)}
              />
            </div>
            <div className="column">
              <label className="mb-1rem">User Role</label>
              <select
                className="form-input form-input--lg"
                value={user.role}
                name="role"
                onChange={handleInput(setUser)}
              >
                <option value="">Select</option>
                {Object.keys(USER_ROLE).map(key => (
                  <option value={key}>{USER_ROLE[key]}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="row mt-2rem mb-2rem">
            {/* <div className="column">
              <label className="mb-1rem">Title</label>
              <select
                className="form-input form-input--lg"
                value={user.title}
                name="title"
                onChange={handleInput(setUser)}
              >
                <option value="">Select</option>
              </select>
            </div> */}

            <div className="column">
              <label className="mb-1rem">Phone Number</label>
              <input
                className="form-input form-input--lg"
                type="text"
                placeholder=""
                name="phoneNumber"
                value={user.phoneNumber}
                onChange={handleInput(setUser)}
              />
            </div>
          </div>

          <h4 className="mt-2rem">Upload Signature</h4>
          <div className="mt-2rem"></div>

          <Button type="primary">
            <label
              style={{
                cursor: "pointer"
              }}
            >
              Upload
              <input
                type="file"
                style={{
                  width: "0.1px",
                  height: "0.1px",
                  opacity: 0,
                  overflow: "hidden",
                  position: "absolute",
                  zIndex: "-1"
                }}
                onChange={handleImageUpload}
              />
            </label>
          </Button>

          <div className="mt-1rem">
            {signature ? (
              <img
                style={{ maxWidth: "auto", maxHeight: "10rem" }}
                src={signature}
                alt={`${user.firstName} ${user.lastName}`}
              />
            ) : (
              <span style={{ fontSize: "75%" }} className="text--muted">
                No signature selected.
              </span>
            )}
          </div>

          <hr className="mt-4rem mb-4rem" />

          <Button type="primary" loading={isSubmitting} onClick={handleSubmit}>
            Update User
          </Button>
        </div>
      ) : (
        "Please wait..."
      )}
    </Card>
  );
};

export default connect(state => ({
  token: state.token
}))(EditUser);
