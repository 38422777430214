import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import * as R from "ramda";
import { parseISO, format, getUnixTime } from "date-fns";
import { Table, Modal } from "antd";
import { navigate } from "@reach/router";

import { formatNumber } from "utils/format";
import { handleApproval } from "utils/approvals";
import {
  INSTRUCTION_STATUS,
  APPROVAL_STATUS,
  ISFD_PROCESS_TYPE,
  PERIOD
} from "constants/index";
import { setIssuerSideFixedDeposits } from "actions/issuer-side-fixed-deposits";

import { Card } from "components/Card";
import isPreTrade from "utils/is-pretrade";

const Home = ({
  token,
  funds,
  user,
  issuers,
  approvers,
  setIssuerSideFixedDeposits,
  issuerSideFixedDeposits
}) => {
  const [visible, setVisible] = React.useState(false);
  const [rejectionMessage, setRejectionMessage] = React.useState("");
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [selectedRecord, setSelectedRecord] = React.useState({});

  const tableColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => b.id - a.id,
      defaultSortOrder: "ascend"
    },
    {
      title: "Created On",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => (
        <span>{format(parseISO(record.createdAt), "dd/MM/yyyy")}</span>
      )
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text, record) => (
        <span
          className="pointer"
          onClick={() =>
            navigate(`issuer-side-fixed-deposits/edit/${record.id}`)
          }
        >
          {record.title}
        </span>
      )
    },
    {
      title: "Tenor",
      dataIndex: "tenor",
      key: "tenor",
      render: (text, record) => (
        <span>
          {record.tenor.count} {PERIOD[record.tenor.period]}
        </span>
      )
    },
    {
      title: "Rate (%)",
      dataIndex: "rate",
      key: "rate",
      render: (text, record) => <span>{formatNumber(record.rate)}</span>
    },
    {
      title: "Process Type",
      dataIndex: "processType",
      key: "processType",
      render: (text, record) => (
        <span className={ISFD_PROCESS_TYPE[record.processType]}>
          {ISFD_PROCESS_TYPE[record.processType]}
        </span>
      )
    },
    {
      title: "Issuer",
      dataIndex: "issuer",
      render: (text, record) => (
        <span>
          {issuers
            ? issuers.find(issuer => issuer.id === record.issuerId).number
            : "--"}
        </span>
      )
    },
    {
      title: "PDF",
      dataIndex: "pdfFilename",
      key: "pdfFilename",
      render: (text, record) => (
        <>
          {record.pdfFilename ? (
            <a
              href={`${process.env.REACT_APP_BASE_URL}/pdf/${record.pdfFilename}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              View
            </a>
          ) : (
            <em className="text--muted">Not available</em>
          )}
        </>
      )
    },
    {
      title: "Signatories",
      dataIndex: "approvers",
      key: "approvers",
      render: (text, record) => (
        <>
          {record.approvers.length ? (
            record.approvers.map(approver => (
              <span
                key={approver.id}
                title={`${approver.firstName} ${approver.lastName}: ${
                  APPROVAL_STATUS[
                    record.groupedApprovals[approver.id][0].status
                  ].text
                }`}
                className={`mr-1rem badge ${
                  APPROVAL_STATUS[
                    record.groupedApprovals[approver.id][0].status
                  ].style
                }`}
              >
                {approver.firstName.charAt(0)}
                {approver.lastName.charAt(0)}
              </span>
            ))
          ) : (
            <span className="text--muted">--</span>
          )}
        </>
      )
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <React.Fragment>
          {isPreTrade(record.status) ? (
            <span className="badge badge--orange mr-1rem">Pre-Trade</span>
          ) : null}
          <span className={`badge ${INSTRUCTION_STATUS[record.status].style}`}>
            {INSTRUCTION_STATUS[record.status].text}
          </span>
        </React.Fragment>
      )
    }
  ];

  const approvalParams = {
    instructionIdURL: "issuerSideFixedDepositId",
    token,
    user,
    approvalType: "APPROVAL"
  };

  const rejectionParams = {
    instructionIdURL: "issuerSideFixedDepositId",
    token,
    user,
    approvalType: "REJECTION",
    rejectionMessage
  };

  const handleShowModal = record => {
    setSelectedRecord(record);
    setVisible(true);
  };

  const handleRejection = async (record, params) => {
    setConfirmLoading(true);

    await handleApproval(record, params);

    setConfirmLoading(false);
    setVisible(false);
  };

  const actions = {
    title: "Action",
    key: "action",
    render: (text, record) => (
      <span>
        <button
          type="button"
          onClick={() => handleApproval(record, approvalParams)}
          className="btn btn--green btn--small"
        >
          Verify {record.name}
        </button>
        <button
          type="button"
          onClick={() => {
            handleShowModal(record);
          }}
          className="btn btn--red btn--small"
        >
          Reject
        </button>
      </span>
    )
  };

  const columns =
    user.role === "APPROVER" ? [...tableColumns, actions] : tableColumns;

  const getIssuerSideFixedDeposits = React.useCallback(async () => {
    const getApprovals = async id => {
      try {
        let res = await axios({
          url: `${process.env.REACT_APP_BASE_URL}/approval?issuerSideFixedDepositId=${id}&$sort[createdAt]=-1`,
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        const { data } = await res;
        return data;
      } catch (err) {
        console.error(err);
      }
    };

    const byApprover = R.groupBy(arr => {
      return arr.approverId;
    });

    try {
      let response = await axios({
        headers: {
          Authorization: `Bearer ${token}`
        },
        url: `${process.env.REACT_APP_BASE_URL}/issuer-side-fixed-deposit`,
        method: "GET"
      });
      let { data } = await response;

      let promises = await data.map(async issuerSideFixedDeposit => {
        // Get approvals
        const approvals = await getApprovals(issuerSideFixedDeposit.id);

        // Group approvals
        const groupedApprovals = await byApprover(approvals);

        // Sort each approver's approvals by updatedAt
        for (let approver in groupedApprovals) {
          groupedApprovals[approver].sort((a, b) => {
            return (
              getUnixTime(parseISO(b.updatedAt)) -
              getUnixTime(parseISO(a.updatedAt))
            );
          });
        }
        issuerSideFixedDeposit.groupedApprovals = await groupedApprovals;

        let approverIds = R.keys(groupedApprovals);
        issuerSideFixedDeposit.approvers = await approverIds
          .map(id =>
            approvers.find(approver => Number(approver.id) === Number(id))
          )
          .filter(function(el) {
            return el != null;
          });
        return issuerSideFixedDeposit;
      });

      const withApprovals = await Promise.all(promises);

      setIssuerSideFixedDeposits(withApprovals);
    } catch (err) {
      console.error(err);
    }
  }, [token, setIssuerSideFixedDeposits, approvers]);

  React.useEffect(() => {
    if (issuerSideFixedDeposits.loading && funds.length) {
      getIssuerSideFixedDeposits();
    }
  }, [
    token,
    funds,
    getIssuerSideFixedDeposits,
    issuerSideFixedDeposits.loading
  ]);

  const handleCloseModal = () => {
    setVisible(false);
  };

  return (
    <Card className="p-4rem flex-column">
      <span className="mb-2rem">
        All Issuer-Side Fixed Deposit Instructions
      </span>
      <Table
        style={{ fontSize: "70%" }}
        rowKey={record => record.id}
        columns={columns}
        dataSource={issuerSideFixedDeposits.instructions}
        loading={issuerSideFixedDeposits.loading}
      />
      <Modal
        title="Reject Instruction"
        visible={visible}
        onOk={() => handleRejection(selectedRecord, rejectionParams)}
        onCancel={handleCloseModal}
        confirmLoading={confirmLoading}
      >
        <p className="mb-2rem">Please indicate a reason for rejection:</p>
        <textarea
          className="form-textarea--modal"
          name="rejectionMessage"
          value={rejectionMessage}
          onChange={event => setRejectionMessage(event.target.value)}
          cols="40"
          rows="5"
          autoFocus
        ></textarea>
      </Modal>
    </Card>
  );
};

export default connect(
  state => ({
    token: state.token,
    funds: state.funds,
    issuerSideFixedDeposits: state.issuerSideFixedDeposits,
    user: state.user,
    approvers: state.approvers,
    issuers: state.bankAccounts.filter(bankAccount => {
      return bankAccount.isIssuer;
    })
  }),
  dispatch => ({
    setIssuerSideFixedDeposits: issuerSideFixedDeposits =>
      dispatch(setIssuerSideFixedDeposits(issuerSideFixedDeposits))
  })
)(Home);
