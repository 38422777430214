import axios from "axios";
import { toast } from "react-toastify";

import { unsetDisinvestments } from "actions/disinvestments";
import { unsetEquities } from "actions/equities";
import { unsetFixedDeposits } from "actions/fixed-deposits";
import { unsetIssuerSideFixedDeposits } from "actions/issuer-side-fixed-deposits";
import { unsetTreasuries } from "actions/treasuries";
import store from "store";

export const handleCreateApproval = async params => {
  const idFromType = {
    "government-security": "governmentSecurityId",
    "fixed-deposit": "fixedDepositId",
    "issuer-side-fixed-deposit": "issuerSideFixedDepositId",
    disinvestment: "disinvestmentId",
    withdrawal: "withdrawalId",
    equity: "equityId"
  };

  const actionFromType = {
    "government-security": unsetTreasuries(),
    "fixed-deposit": unsetFixedDeposits(),
    "issuer-side-fixed-deposit": unsetIssuerSideFixedDeposits(),
    disinvestment: unsetDisinvestments(),
    // withdrawal: "withdrawalId",
    equity: unsetEquities
  };

  let promise = params.approvers.map(async approverId => {
    try {
      const res = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/approval`,
        data: {
          approverId,
          [idFromType[params.instructionType]]: params.id,
          kind: params.kind
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${params.token}`
        }
      });
      const { status } = await res;
      const STATUS_CHOICES = {
        PRE_TRADE: "PENDING_INTERNAL_PRE_APPROVAL",
        SETTLEMENT: "PENDING_SETTLEMENT_APPROVAL"
      };
      if (status > 199 && status < 299) {
        axios({
          method: "PATCH",
          url: `${process.env.REACT_APP_BASE_URL}/${params.instructionType}/${params.id}`,
          data: {
            status: STATUS_CHOICES[params.kind]
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${params.token}`
          }
        });
      }
      store.dispatch(actionFromType[params.instructionType]);
    } catch (err) {
      toast.error("Something went wrong. Please try again.");
      store.dispatch(actionFromType[params.instructionType]);
      console.error(err);
    }
  });

  Promise.all(promise).then(toast.success("Approval creation success"));
};

// FIXME: Change instructionIdURL to be consistent with instructionType used in handleCreateApproval
export const handleApproval = async (record, params) => {
  const actionFromId = {
    governmentSecurityId: unsetTreasuries(),
    fixedDepositId: unsetFixedDeposits(),
    issuerSideFixedDepositId: unsetIssuerSideFixedDeposits(),
    disinvestmentId: unsetDisinvestments(),
    // "withdrawalId",
    equityId: unsetEquities()
  };
  try {
    let res = await axios({
      url: `${process.env.REACT_APP_BASE_URL}/approval?${params.instructionIdURL}=${record.id}&$sort[createdAt]=-1`,
      headers: {
        Authorization: `Bearer ${params.token}`
      }
    });

    const approvals = await res.data;
    const approval = approvals.find(
      approval => approval.approverId === params.user.id
    );

    const data = {
      status: params.approvalType === "APPROVAL" ? "APPROVED" : "REJECTED"
    };

    if (params.approvalType === "REJECTION") {
      data.rejectionMessage = params.rejectionMessage;
    }

    if (res.status > 199 && res.status < 299) {
      try {
        await axios({
          method: "PATCH",
          url: `${process.env.REACT_APP_BASE_URL}/approval/${approval.id}`,
          data,
          headers: {
            Authorization: `Bearer ${params.token}`
          }
        });
        toast.success(
          params.approvalType === "APPROVAL"
            ? "Instruction approved"
            : "Instruction rejected"
        );
        store.dispatch(actionFromId[params.instructionIdURL]);
      } catch (err) {
        toast.error("Something went wrong. Please try again");
        store.dispatch(actionFromId[params.instructionIdURL]);
        console.error(err);
      }
    }
  } catch (err) {
    store.dispatch(actionFromId[params.instructionIdURL]);
    console.error(err);
  }
};

// export const isApprover = () => {
//   const user = store.getState().user;
//   if (user.role === "APPROVER") return true;
// };

export const showApprovers = (approvalStatus, isSettlementApproval) => {
  let showApprovers = true;
  const user = store.getState().user;
  if (user.role === "APPROVER") {
    showApprovers = false;
  }
  if (approvalStatus === "PENDING_SETTLEMENT_UPDATE" && !isSettlementApproval) {
    showApprovers = false;
  }
  return showApprovers;
};
