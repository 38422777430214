import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { Button } from "antd";

import { Card } from "components/Card";

const Withdrawal = ({ token, editMode, id }) => {
  const [loaded, setLoaded] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [withdrawal, setWithdrawal] = React.useState("");
  const [message] = React.useState("");
  const [approvers] = React.useState([]);

  React.useEffect(() => {
    if (editMode) {
      setWithdrawal("Here's your withdrawal");
      setLoaded(true);
    } else {
      setWithdrawal("");
      setLoaded(true);
    }
  }, [editMode, withdrawal]);

  const handleSubmit = async event => {
    event.preventDefault();

    const editURL = `${process.env.REACT_APP_BASE_URL}/withdrawal/${id}`;
    const createURL = `${process.env.REACT_APP_BASE_URL}/withdrawal`;

    try {
      setIsSubmitting(true);
      const res = await axios({
        method: editMode ? "PUT" : "POST",
        url: editMode ? editURL : createURL,
        // data: data,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      });
      const { status, data: result } = await res;

      let successMessageEdit = "Withdrawal details updated successfully.";
      let successMessageCreate = "Withdrawal creation success.";
      if (status > 199 && status < 299) {
        toast.success(
          `${editMode ? successMessageEdit : successMessageCreate}`
        );
      }

      if (approvers.length) {
        await this.handleCreateApproval(result.id);
      }

      // this.setState({
      //   ...initialState,
      //   isSubmitting: false
      // });
      setIsSubmitting(false);
    } catch (err) {
      toast.error("Something went wrong. Please try again.");
      setIsSubmitting(false);
      console.error(err);
    }
  };

  return (
    <Card className="p-4rem flex-column">
      {loaded ? (
        <div style={{ width: "70%" }}>
          <h3>{editMode ? "Edit Withdrawal" : "Create Withdrawal"}</h3>
          <form onSubmit={handleSubmit}>
            <Button type="primary" htmlType="submit" loading={isSubmitting}>
              {editMode ? "Save and Update" : "Create Withdrawal"}
            </Button>
            {message ? <p style={{ color: "red" }}>{message}</p> : null}
          </form>
        </div>
      ) : (
        "Please wait..."
      )}
    </Card>
  );
};

export default connect(state => ({
  token: state.token
}))(Withdrawal);
