import { actionTypes } from "constants/index";

export const funds = (state = [], action) => {
  switch (action.type) {
    case actionTypes.SET_FUNDS:
      return action.payload;
    default:
      return state;
  }
};
