import { actionTypes } from "constants/index";

const initialState = {
  instructions: [],
  loading: true
};

export const equities = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_EQUITIES:
      return {
        loading: false,
        instructions: action.payload
      };
    case actionTypes.UNSET_EQUITIES:
      return initialState;
    default:
      return state;
  }
};

export const treasuries = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_TREASURIES:
      return {
        loading: false,
        instructions: action.payload
      };
    case actionTypes.UNSET_TREASURIES:
      return initialState;
    default:
      return state;
  }
};

export const fixedDeposits = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_FDS:
      return {
        loading: false,
        instructions: action.payload
      };
    case actionTypes.UNSET_FDS:
      return initialState;
    default:
      return state;
  }
};

export const issuerSideFixedDeposits = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ISFDS:
      return {
        loading: false,
        instructions: action.payload
      };
    case actionTypes.UNSET_ISFDS:
      return initialState;
    default:
      return state;
  }
};

export const disinvestments = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_DISINVESTMENTS:
      return {
        loading: false,
        instructions: action.payload
      };
    case actionTypes.UNSET_DISINVESTMENTS:
      return initialState;
    default:
      return state;
  }
};
