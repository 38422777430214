import React from "react";

import { Card } from "components/Card";

const CreateFund = () => {
  const [name, setName] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [custodian, setCustodian] = React.useState("");
  const [fundAccountNumber, setFundAccountNumber] = React.useState("");
  const [csdNumber, setCsdNumber] = React.useState("");
  const [trustee, setTrustee] = React.useState("");
  const [designation, setDesignation] = React.useState("");
  const [mailingList, setMailingList] = React.useState("");

  const handleChange = setValue => event => {
    setValue(event.target.value);
  };

  const onSubmit = event => {
    event.preventDefault();
    // const data = {
    //   name,
    //   address,
    //   custodian,
    //   fundAccountNumber,
    //   csdNumber,
    //   trustee,
    //   designation,
    //   mailingList
    // };
  };

  const onCancel = event => {
    if (event) event.preventDefault();
    setName("");
    setAddress("");
    setCustodian("");
    setFundAccountNumber("");
    setCsdNumber("");
    setTrustee("");
    setDesignation("");
    setMailingList("");
  };
  return (
    <Card className="p-4rem flex-column">
      <form onSubmit={onSubmit} style={{ width: "70%" }}>
        <h4>Create Fund</h4>

        <div className="row mt-2rem mb-2rem">
          <div className="column">
            <label className="mb-1rem">Fund Name</label>
            <input
              className="form-input form-input--lg"
              type="text"
              placeholder=""
              value={name}
              onChange={handleChange(setName)}
            />
          </div>
          <div className="column">
            <label className="mb-1rem">Fund Address</label>
            <input
              className="form-input form-input--lg"
              type="text"
              placeholder=""
              value={address}
              onChange={handleChange(setAddress)}
            />
          </div>
        </div>

        <div className="row mt-2rem mb-2rem">
          <div className="column">
            <label className="mb-1rem">Custodian</label>
            <input
              className="form-input form-input--lg"
              type="text"
              placeholder=""
              value={custodian}
              onChange={handleChange(setCustodian)}
            />
          </div>
          <div className="column">
            <label className="mb-1rem">Fund Account Number</label>
            <input
              className="form-input form-input--lg"
              type="text"
              placeholder=""
              value={fundAccountNumber}
              onChange={handleChange(setFundAccountNumber)}
            />
          </div>
        </div>

        <div className="row mt-2rem mb-2rem">
          <div className="column">
            <label className="mb-1rem">CSD Number</label>
            <input
              className="form-input form-input--lg"
              type="text"
              placeholder=""
              value={csdNumber}
              onChange={handleChange(setCsdNumber)}
            />
          </div>
          <div className="column">
            <label className="mb-1rem">Fund Trustee</label>
            <input
              className="form-input form-input--lg"
              type="text"
              placeholder=""
              value={trustee}
              onChange={handleChange(setTrustee)}
            />
          </div>
        </div>

        <div className="row mt-2rem mb-2rem">
          <div className="column">
            <label className="mb-1rem">Designation</label>
            <input
              className="form-input form-input--lg"
              type="text"
              placeholder=""
              value={designation}
              onChange={handleChange(setDesignation)}
            />
          </div>
          <div className="column">
            <label className="mb-1rem">Mailing List</label>
            <input
              className="form-input form-input--lg"
              type="text"
              placeholder=""
              value={mailingList}
              onChange={handleChange(setMailingList)}
            />
          </div>
        </div>
        <button>Create</button>
        <button type="button" onClick={() => onCancel()}>
          Cancel
        </button>
      </form>
    </Card>
  );
};

export default CreateFund;
