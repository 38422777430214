import { useState } from "react";

const useForm = cb => {
  const [values, setValues] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async event => {
    if (event) event.preventDefault();
    setIsSubmitting(true);
    await cb();
    setIsSubmitting(false);
  };

  const handleInput = event => {
    event.persist();
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setValues(values => ({ ...values, [name]: value }));
  };

  return {
    handleInput,
    handleSubmit,
    values,
    isSubmitting
  };
};

export default useForm;
