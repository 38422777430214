import { actionTypes } from "constants/index";

export const setToken = token => ({
  type: actionTypes.SET_TOKEN,
  payload: token
});

export const unsetToken = () => ({
  type: actionTypes.UNSET_TOKEN
});
