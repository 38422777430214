import { actionTypes } from "constants/index";

let dmsToken = sessionStorage.getItem("dmsToken");
if (!dmsToken) dmsToken = "";
const initialState = dmsToken;

export const token = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_TOKEN:
      sessionStorage.setItem("dmsToken", action.payload);
      return action.payload;
    case actionTypes.UNSET_TOKEN:
      sessionStorage.removeItem("dmsToken");
      return "";
    default:
      return state;
  }
};
