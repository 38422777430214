import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import DatePicker from "react-datepicker";
import { parseISO } from "date-fns";
import { toast } from "react-toastify";
import { Button } from "antd";

import { formatNumber } from "utils/format";
import { Card } from "components/Card";
import { PERIOD, ISFD_PROCESS_TYPE } from "constants/index";
import { handleCreateApproval, showApprovers } from "utils/approvals";
import { unsetIssuerSideFixedDeposits } from "actions/issuer-side-fixed-deposits";

const initialState = {
  effectiveDate: "",
  isCallable: false,
  entries: [{ fundId: "", amount: "" }],
  issuerId: "",
  maturityInterestDate: "",
  note: "",
  principalAmount: "",
  principalAmountMessage: "",
  processType: "",
  rate: "",
  tenor: {
    count: "",
    period: ""
  },
  title: "",
  approvers: [],
  isSettlementApproval: false
};

class IssuerSideFixedDeposit extends React.Component {
  state = {
    ...initialState,
    loaded: false,
    isSubmitting: false
  };

  async componentDidMount() {
    if (this.props.editMode) {
      const getFund = async () => {
        let { data: result } = await axios({
          headers: {
            Authorization: `Bearer ${this.props.token}`
          },
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/issuer-side-fixed-deposit/${this.props.id}`
        });
        const data = {
          ...result,
          rate: formatNumber(result.rate),
          effectiveDate: parseISO(result.effectiveDate),
          maturityInterestDate: parseISO(result.maturityInterestDate)
        };
        this.setState({
          ...data
        });
      };

      await getFund();

      if (this.props.id) {
        const { data } = await axios({
          method: "get",
          headers: {
            Authorization: `Bearer ${this.props.token}`
          },
          url: `${process.env.REACT_APP_BASE_URL}/fixed-deposit?issuerSideId=${this.props.id}`
        });
        const entries = data.map(data => {
          return {
            id: data.id,
            fundId: data.fundId,
            amount: data.principalAmount
          };
        });
        this.setState({ entries });
      }

      this.setState({ loaded: true });
    } else {
      this.setState({
        ...initialState,
        loaded: true
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.entries !== this.state.entries ||
      prevState.processType !== this.state.processType
    ) {
      this.calculatePrincipalAmount(this.state.processType);
    }
  }

  calculatePrincipalAmount = () => {
    if (
      this.state.processType === "MATURITY" ||
      this.state.processType === "INTEREST" ||
      this.state.processType === ""
    ) {
      let principalAmount = this.state.entries.reduce((acc, currVal) => {
        return Number(acc) + Number(currVal.amount);
      }, null);
      this.setState({ principalAmount, principalAmountMessage: "" });
    } else {
      this.setState({
        principalAmount: "",
        principalAmountMessage: "Not available for this process type"
      });
    }
  };

  handleInput = event => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value });
  };

  handleAddFund = event => {
    event.preventDefault();
    let newState = this.state;
    newState = {
      ...this.state,
      entries: this.state.entries.concat([{ fundId: "", amount: "" }])
    };
    this.setState({
      ...newState
    });
  };

  handleUpdateFund = id => event => {
    let { name, value } = event.target;
    let newState = this.state.entries.map((entry, entryId) => {
      if (entryId !== id) return entry;
      return {
        ...entry,
        [name]: value
      };
    });
    this.setState({ entries: newState });
    // this.setState({ ...newState });
  };

  handleFund = event => {
    let { name, value } = event.target;
    this.setState({ [name]: value });

    if (event.target.value) {
      let { csdAccountId } = this.props.funds.find(
        fund => Number(fund.id) === Number(value)
      );
      let csdAccountNumber = this.props.csdAccounts.find(
        csdAccount => Number(csdAccount.id) === Number(csdAccountId)
      ).number;

      let { bankAccountId } = this.props.funds.find(
        fund => Number(fund.id) === Number(value)
      );
      let bankAccountNumber = this.props.bankAccounts.find(
        bankAccount => Number(bankAccount.id) === Number(bankAccountId)
      ).number;

      this.setState({
        csdAccountNumber,
        bankAccountNumber
      });
    } else {
      this.setState({
        csdAccountNumber: "",
        bankAccountNumber: ""
      });
    }
  };

  handleNested = stateName => event => {
    let { name, value } = event.target;
    this.setState({
      ...this.state,
      [stateName]: {
        ...this.state[stateName],
        [name]: value
      }
    });
  };

  handleCheckbox = event => {
    const approvers = this.state.approvers;
    let index;
    if (event.target.checked) {
      approvers.push(event.target.value);
    } else {
      index = approvers.indexOf(event.target.value);
      approvers.splice(index, 1);
    }
    this.setState({ approvers });
  };

  handleDateChange = stateName => date => {
    this.setState({
      [stateName]: date
    });
  };

  handleSubmit = async event => {
    event.preventDefault();
    const newData = {
      effectiveDate: this.state.effectiveDate,
      isCallable: this.state.isCallable,
      issuerId: this.state.issuerId,
      entries: this.state.entries,
      maturityInterestDate: this.state.maturityInterestDate,
      note: this.state.note,
      principalAmount: String(this.state.principalAmount),
      processType: this.state.processType,
      rate: this.state.rate,
      tenor: this.state.tenor,
      title: this.state.title,
      status: "PENDING_SETTLEMENT_APPROVAL"
    };

    const updateData = {
      effectiveDate: this.state.effectiveDate,
      isCallable: this.state.isCallable,
      issuerId: this.state.issuerId,
      maturityInterestDate: this.state.maturityInterestDate,
      note: this.state.note,
      // principalAmount: String(this.state.principalAmount),
      processType: this.state.processType,
      rate: this.state.rate,
      tenor: this.state.tenor,
      title: this.state.title
    };

    const editURL = `${process.env.REACT_APP_BASE_URL}/issuer-side-fixed-deposit/${this.props.id}`;
    const createURL = `${process.env.REACT_APP_BASE_URL}/issuer-side-fixed-deposit`;

    try {
      this.setState({ isSubmitting: true });
      const res = axios({
        method: this.props.editMode ? "PUT" : "POST",
        url: this.props.editMode ? editURL : createURL,
        data: this.props.editMode ? updateData : newData,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.token}`
        }
      });
      const { status, data: result } = await res;

      let successMessageEdit =
        "Issuer-side fixed deposit details updated successfully.";
      let successMessageCreate = "Issuer-side fixed deposit creation success.";
      if (status > 199 && status < 299) {
        toast.success(
          `${this.props.editMode ? successMessageEdit : successMessageCreate}`
        );
      }

      if (this.state.approvers.length) {
        let params = {
          id: result.id,
          instructionType: "issuer-side-fixed-deposit",
          token: this.props.token,
          approvers: this.state.approvers,
          kind: "SETTLEMENT"
        };
        await handleCreateApproval(params);
      }

      if (this.props.editMode) {
        this.state.entries.map(async entry => {
          if (entry.id) {
            try {
              await axios({
                method: "put",
                headers: {
                  Authorization: `Bearer ${this.props.token}`
                },
                data: {
                  fundId: entry.fundId,
                  principalAmount: entry.amount
                },
                url: `${process.env.REACT_APP_BASE_URL}/fixed-deposit/${entry.id}`
              });
            } catch (err) {
              console.log(err);
            }
          }
        });
        // await axios({
        //   method: "put",
        //   headers: {
        //     Authorization: `Bearer ${this.props.token}`
        //   },
        //   data: this.state.entries,
        //   url: `${process.env.REACT_APP_BASE_URL}/fixed-deposit/${this.props.id}`
        // });
        // const entries = data.map(data => {
        //   return {
        //     fundId: data.fundId,
        //     amount: data.principalAmount
        //   };
        // });
      }

      this.props.unsetIssuerSideFixedDeposits();
      if (this.props.editMode) {
        this.setState({
          isSubmitting: false
        });
      } else {
        this.setState({
          ...initialState,
          isSubmitting: false
        });
      }
    } catch (err) {
      toast.error("Something went wrong. Please try again.");
      this.setState({ isSubmitting: false });
      console.error(err);
    }
  };

  render() {
    const isApprover = this.props.user.role === "APPROVER";
    const displayApprovers = showApprovers(
      this.state.status,
      this.state.isSettlementApproval
    );

    return (
      <Card className="p-4rem flex-column">
        {this.state.loaded ? (
          <div style={{ width: "70%" }}>
            <h3>
              {this.props.editMode
                ? "Edit Issuer-Side Fixed Deposit Instruction"
                : "Create Issuer-Side Fixed Deposit Instruction"}
            </h3>
            <form onSubmit={this.handleSubmit}>
              <div className="row mt-2rem mb-2rem">
                <div className="column">
                  <label className="mb-1rem">Process Type</label>
                  <select
                    onChange={this.handleInput}
                    value={this.state.processType}
                    name="processType"
                    className="form-input form-input--lg"
                    disabled={isApprover}
                  >
                    <option value="">Select</option>
                    {Object.keys(ISFD_PROCESS_TYPE).map(key => (
                      <option key={key} value={key}>
                        {ISFD_PROCESS_TYPE[key]}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {this.state.entries.map((entry, id) => (
                <div key={id} className="row mt-2rem mb-2rem">
                  <div className="column">
                    <label className="mb-1rem">Fund</label>
                    <select
                      onChange={this.handleUpdateFund(id)}
                      value={entry.fundId}
                      name="fundId"
                      className="form-input form-input--lg"
                      disabled={isApprover}
                    >
                      <option value="">Select a fund</option>
                      {this.props.funds
                        ? this.props.funds.map(fund => (
                            <option key={fund.id} value={fund.id}>
                              {fund.name}
                            </option>
                          ))
                        : null}
                    </select>
                  </div>
                  <div className="column">
                    <label className="mb-1rem">Amount</label>
                    <input
                      className="form-input form-input--lg"
                      type="number"
                      step="0.01"
                      min="0"
                      placeholder=""
                      onChange={this.handleUpdateFund(id)}
                      value={entry.amount}
                      name="amount"
                      disabled={isApprover}
                    />
                  </div>
                </div>
              ))}

              <div>
                <Button
                  style={{ marginBottom: "2rem" }}
                  type="primary"
                  onClick={this.handleAddFund}
                >
                  Add entry
                </Button>
              </div>

              <label htmlFor="">
                <input
                  type="checkbox"
                  checked={this.state.isCallable}
                  value={this.state.isCallable}
                  name="isCallable"
                  onChange={this.handleInput}
                  disabled={isApprover}
                />
                <span className="ml-1rem">Callable</span>
              </label>

              <hr className="mt-4rem mb-4rem" />

              <div className="row mt-2rem mb-2rem">
                <div className="column">
                  <label className="mb-1rem">Title</label>
                  <input
                    className="form-input form-input--lg"
                    type="text"
                    placeholder=""
                    onChange={this.handleInput}
                    value={this.state.title}
                    name="title"
                    disabled={isApprover}
                  />
                </div>
                {this.state.processType === "MATURITY" ||
                this.state.processType === "INTEREST" ||
                this.state.processType === "" ? (
                  <div className="column">
                    <label className="mb-1rem">Principal Amount</label>
                    <input
                      className="form-input form-input--lg"
                      type="number"
                      step="0.01"
                      placeholder=""
                      readOnly
                      // onChange={this.handleInput}
                      value={this.state.principalAmount}
                      name="title"
                      disabled={isApprover}
                    />
                    <small
                      style={{
                        color: "red",
                        visibility: this.state.principalAmountMessage
                          ? "visible"
                          : "hidden"
                      }}
                    >
                      {this.state.principalAmountMessage}
                    </small>
                  </div>
                ) : null}
              </div>

              <div className="row mt-2rem mb-2rem">
                <div className="column">
                  <label className="mb-1rem">Tenor</label>
                  <input
                    className="form-input form-input--lg"
                    type="number"
                    max="365"
                    min="1"
                    placeholder=""
                    name="count"
                    value={this.state.tenor.count}
                    onChange={this.handleNested("tenor")}
                    disabled={isApprover}
                  />
                </div>
                <div className="column">
                  <label className="mb-1rem">Period</label>
                  <select
                    onChange={this.handleNested("tenor")}
                    name="period"
                    value={this.state.tenor.period}
                    className="form-input form-input--lg"
                    disabled={isApprover}
                  >
                    <option value="">Select one</option>
                    {Object.keys(PERIOD).map(key => (
                      <option key={key} value={key}>
                        {PERIOD[key]}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row mt-2rem mb-2rem">
                <div className="column">
                  <label className="mb-1rem">Effective Date</label>
                  <DatePicker
                    className="format-input"
                    selected={this.state.effectiveDate}
                    onChange={this.handleDateChange("effectiveDate")}
                    dateFormat="dd/MM/yyyy"
                    disabled={isApprover}
                  />
                </div>
                {/* {this.state.processType === "MATURITY" ||
                this.state.processType === "INTEREST" ? ( */}
                <div className="column">
                  <label className="mb-1rem">
                    {/* {this.state.processType === "MATURITY"
                        ? "Maturity"
                        : "Interest"}{" "} */}
                    Date
                  </label>
                  <DatePicker
                    className="format-input"
                    selected={this.state.maturityInterestDate}
                    onChange={this.handleDateChange("maturityInterestDate")}
                    dateFormat="dd/MM/yyyy"
                    disabled={isApprover}
                  />
                </div>
                {/* ) : null} */}
              </div>
              <div className="row mt-2rem mb-2rem">
                <div className="column">
                  <label className="mb-1rem">Issuer</label>
                  {/* <input
                    className="form-input form-input--lg"
                    type="text"
                    placeholder=""
                    onChange={this.handleInput}
                    value={this.state.issuer}
                    name="issuer"
                  /> */}
                  <select
                    onChange={this.handleInput}
                    name="issuerId"
                    value={this.state.issuerId}
                    className="form-input form-input--lg"
                    disabled={isApprover}
                  >
                    <option value="">Select one</option>
                    {this.props.issuers
                      ? this.props.issuers.map(issuer => (
                          <option key={issuer.id} value={issuer.id}>
                            {issuer.beneficiary} ({issuer.number})
                          </option>
                        ))
                      : null}
                  </select>
                </div>
                <div className="column">
                  <label className="mb-1rem">Rate</label>
                  <input
                    className="form-input form-input--lg"
                    type="text"
                    placeholder=""
                    value={this.state.rate}
                    name="rate"
                    onChange={this.handleInput}
                    disabled={isApprover}
                  />
                </div>
              </div>

              <div className="row mt-2rem mb-2rem">
                <div className="column">
                  <label className="mb-1rem">Note</label>
                  <textarea
                    className="form-textarea"
                    name="note"
                    value={this.state.note}
                    onChange={this.handleInput}
                    cols="30"
                    rows="6"
                    disabled={isApprover}
                  ></textarea>
                </div>
              </div>

              {!isApprover && (
                <React.Fragment>
                  <hr className="mt-4rem mb-4rem" />

                  {displayApprovers && (
                    <React.Fragment>
                      <h4 className="mt-2rem">Co-signers:</h4>
                      {this.props.approvers
                        ? this.props.approvers.map(approver => (
                            <div key={approver.id}>
                              <label htmlFor="">
                                <input
                                  disabled={this.props.user.id === approver.id}
                                  type="checkbox"
                                  onChange={this.handleCheckbox}
                                  value={approver.id}
                                  name=""
                                  id=""
                                />
                                <span className="ml-1rem">
                                  {approver.firstName} {approver.lastName}
                                </span>
                              </label>
                            </div>
                          ))
                        : null}
                    </React.Fragment>
                  )}

                  {/* <label htmlFor="">
                    <input
                      className="mt-4rem"
                      type="checkbox"
                      value={this.state.isSettlementApproval}
                      name="isSettlementApproval"
                      onChange={this.handleInput}
                    />
                    <span className="ml-1rem">Create settlement approval</span>
                  </label> */}

                  <hr className="mt-4rem mb-4rem" />

                  <div className="mt-2rem"></div>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={this.state.isSubmitting}
                  >
                    {this.props.editMode
                      ? "Save and Update"
                      : "Create Instruction"}
                  </Button>
                </React.Fragment>
              )}
            </form>
          </div>
        ) : (
          "Please wait..."
        )}
      </Card>
    );
  }
}

export default connect(
  state => ({
    token: state.token,
    funds: state.funds,
    approvers: state.approvers,
    user: state.user,
    csdAccounts: state.csdAccounts,
    bankAccounts: state.bankAccounts,
    issuers: state.bankAccounts.filter(bankAccount => {
      return bankAccount.isIssuer;
    })
  }),
  dispatch => ({
    unsetIssuerSideFixedDeposits: () => dispatch(unsetIssuerSideFixedDeposits())
  })
)(IssuerSideFixedDeposit);
