import { actionTypes } from "constants/index";

export const setEquities = equities => ({
  type: actionTypes.SET_EQUITIES,
  payload: equities
});

export const unsetEquities = () => ({
  type: actionTypes.UNSET_EQUITIES
});
