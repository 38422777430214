import React from "react";
import { Link } from "@reach/router";

import chevron from "images/svg/chevron.svg";

const Sider = props => {
  const { data } = props;
  return (
    <div className="sider">
      <p className="p-2rem sider__title">{props.current}</p>
      <div className="flex-column">
        {data ? (
          data.map(datum => (
            <Link
              key={datum.name}
              className="px-2rem py-1rem sider__item"
              to={datum.path}
            >
              <img
                style={{ width: "1rem", height: "1rem" }}
                src={chevron}
                alt=""
              />{" "}
              {datum.name}
            </Link>
          ))
        ) : (
          <p className="p-2rem">No data.</p>
        )}
      </div>
    </div>
  );
};

export default Sider;
