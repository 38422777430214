import React, { Component } from "react";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import axios from "axios";
import { parseISO } from "date-fns";
import { Button, Modal } from "antd";

import { PROCESS_TYPE, TRANSACTION_TYPE, PERIOD } from "constants/index";
import { unsetTreasuries } from "actions/treasuries";
import { setFunds } from "actions/funds";
import { getInitialData, getBanks } from "utils/get-thingy";
import { handleCreateApproval, showApprovers } from "utils/approvals";

import { Card } from "components/Card";

const initialState = {
  brokerAccountId: "",
  consideration: "",
  counterpartyId: "",
  couponRate: "",
  csdAccountNumber: "",
  bankAccountNumber: "",
  exposure: {
    pre: "",
    post: "",
    max: ""
  },
  fundId: "",
  isin: "",
  maturityDate: "",
  nominal: "",
  note: "",
  price: "",
  processType: "",
  repoCheckSum: null,
  repoDuration: null,
  securityDescription: "",
  settlementDate: "",
  tenor: {
    count: "",
    period: ""
  },
  tradeDate: "",
  transactionType: "",
  yieldRate: "",
  broker: {
    bankName: "",
    number: "",
    branch: "",
    moniker: ""
  },
  approvers: [],
  isSettlementApproval: false,
  validationErrors: {
    securityDescription: false
  },

  // Add bank account
  addCounterparty: false,
  addBroker: false,
  addAccountLoading: false,
  beneficiary: "",
  bankName: "",
  swiftCode: "",
  number: "",
  moniker: "",
  contactPerson: "",
  branch: "",
  address: ""
};

class Treasury extends Component {
  state = {
    ...initialState,
    loaded: false,
    isSubmitting: false
  };

  async componentDidMount() {
    getInitialData();
    if (this.props.editMode) {
      // TODO: Approvers should not be able to land on edit routes
      // if (this.props.user.role === "APPROVER") {
      //   // window.history.back();
      //   navigate(-1);
      // }
      const getFunds = async () => {
        let response = await axios({
          headers: {
            Authorization: `Bearer ${this.props.token}`
          },
          url: `${process.env.REACT_APP_BASE_URL}/fund`,
          method: "GET"
        });
        let { data } = await response;
        await setFunds(data);
      };
      await getFunds();
      const setCSD = async id => {
        if (this.props.funds) {
          let { csdAccountId } = await this.props.funds.find(
            fund => Number(fund.id) === Number(id)
          );
          let { number } = await this.props.csdAccounts.find(
            csdAccount => Number(csdAccount.id) === Number(csdAccountId)
          );
          this.setState({
            csdAccountNumber: number
          });
        }
      };

      const setBankAccount = async id => {
        if (this.props.funds) {
          let { bankAccountId } = await this.props.funds.find(
            fund => Number(fund.id) === Number(id)
          );
          let { number } = await this.props.bankAccounts.find(
            bankAccount => Number(bankAccount.id) === Number(bankAccountId)
          );
          this.setState({
            bankAccountNumber: number
          });
        }
      };

      const setBroker = async id => {
        if (this.props.brokers) {
          let broker = await this.props.brokers.find(
            broker => Number(broker.id) === Number(id)
          );
          if (broker) {
            this.setState({
              broker
            });
          } else {
            this.setState({
              broker: {}
            });
          }
        }
      };

      const setCounterparty = async id => {
        if (this.props.counterparties) {
          let counterparty = await this.props.counterparties.find(
            counterparty => Number(counterparty.id) === Number(id)
          );
          if (counterparty) {
            this.setState({
              counterpartyId: counterparty.id
            });
          } else {
            this.setState({
              counterpartyId: ""
            });
          }
        }
      };

      // const setIsSettlementApproval = status => {
      //   if (
      //     status === "NEW" ||
      //     status === "PENDING_INTERNAL_PRE_APPROVAL" ||
      //     status === "SENDING_PRE_APPROVAL_FAILED" ||
      //     status === "PENDING_EXTERNAL_PRE_APPROVAL" ||
      //     status === "EXTERNAL_PRE_APPROVAL_REJECTED"
      //   ) {
      //     return false;
      //   } else return true;
      // };

      const getFund = async () => {
        let { data: result } = await axios({
          headers: {
            Authorization: `Bearer ${this.props.token}`
          },
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/government-security/${this.props.id}`
        });
        const data = {
          ...result,
          settlementDate: parseISO(result.settlementDate),
          tradeDate: parseISO(result.tradeDate),
          maturityDate: parseISO(result.maturityDate),
          // isSettlementApproval: setIsSettlementApproval(result.status),
          broker: {}
        };
        await this.setState({
          ...data
        });
        await setCSD(result.fundId);
        await setBankAccount(result.fundId);
        await setBroker(result.brokerAccountId);
        await setCounterparty(result.counterpartyId);
        await this.setState({ loaded: true });
      };

      await getFund();
    } else {
      this.setState({
        ...initialState,
        loaded: true
      });
    }
  }

  handleInput = event => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value });
  };

  handleFund = event => {
    let { name, value } = event.target;
    this.setState({ [name]: value });

    if (event.target.value) {
      let { csdAccountId } = this.props.funds.find(
        fund => Number(fund.id) === Number(value)
      );
      let csdAccountNumber = this.props.csdAccounts.find(
        csdAccount => Number(csdAccount.id) === Number(csdAccountId)
      ).number;

      let { bankAccountId } = this.props.funds.find(
        fund => Number(fund.id) === Number(value)
      );
      let bankAccountNumber = this.props.bankAccounts.find(
        bankAccount => Number(bankAccount.id) === Number(bankAccountId)
      ).number;

      this.setState({
        csdAccountNumber,
        bankAccountNumber
      });
    } else {
      this.setState({
        csdAccountNumber: "",
        bankAccountNumber: ""
      });
    }
  };

  handleNested = stateName => event => {
    let { name, value } = event.target;
    this.setState({
      ...this.state,
      [stateName]: {
        ...this.state[stateName],
        [name]: value
      }
    });
  };

  handleBroker = event => {
    let { value } = event.target;
    if (value === "addBroker") {
      this.setState({ addBroker: true });
    } else if (value !== "") {
      let broker = this.props.brokers.find(
        broker => Number(broker.id) === Number(value)
      );
      this.setState({
        broker
      });
    } else {
      this.setState({
        broker: {
          bankName: "",
          number: "",
          branch: "",
          moniker: ""
        }
      });
    }

    // if (value) {
    //   let broker = this.props.brokers.find(
    //     broker => Number(broker.id) === Number(value)
    //   );
    //   this.setState({
    //     broker
    //   });
    // } else {
    //   this.setState({
    //     broker: {}
    //   });
    // }
  };

  handleCheckbox = event => {
    const approvers = this.state.approvers;
    let index;
    if (event.target.checked) {
      approvers.push(event.target.value);
    } else {
      index = approvers.indexOf(event.target.value);
      approvers.splice(index, 1);
    }
    this.setState({ approvers });
  };

  handleDateChange = stateName => date => {
    this.setState({
      [stateName]: date
    });
  };

  handleCounterparty = event => {
    event.target.value === "addCounter"
      ? this.setState({ addCounterparty: true })
      : this.setState({
          addCounterparty: false,
          counterpartyId: event.target.value
        });
  };

  resetAccount = () => {
    this.setState({
      beneficiary: "",
      bankName: "",
      swiftCode: "",
      number: "",
      moniker: "",
      contactPerson: "",
      branch: "",
      address: ""
    });
  };

  handleCreateCounterparty = async event => {
    event.preventDefault();
    const data = {
      beneficiary: this.state.beneficiary,
      bankName: this.state.bankName,
      swiftCode: this.state.swiftCode,
      number: this.state.number,
      moniker: this.state.moniker,
      contactPerson: this.state.contactPerson,
      branch: this.state.branch,
      address: this.state.address,
      isCounterparty: true
    };

    this.setState({ addAccountLoading: true });
    try {
      await axios({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/bank-account`,
        data: data,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.token}`
        }
      });
      toast.success("Counterparty added");
      getBanks(this.props.token);
    } catch (err) {
      console.error(err);
      toast.error("Something went wrong. Please try again");
    }
    this.setState({ addCounterparty: false, addAccountLoading: false });
    this.resetAccount();
  };

  handleCreateBroker = async event => {
    event.preventDefault();
    const data = {
      beneficiary: this.state.beneficiary,
      bankName: this.state.bankName,
      swiftCode: this.state.swiftCode,
      number: this.state.number,
      moniker: this.state.moniker,
      contactPerson: this.state.contactPerson,
      branch: this.state.branch,
      address: this.state.address,
      isBroker: true
    };

    this.setState({ addAccountLoading: true });
    try {
      await axios({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/bank-account`,
        data: data,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.token}`
        }
      });
      toast.success("Broker added");
      getBanks(this.props.token);
    } catch (err) {
      console.error(err);
      toast.error("Something went wrong. Please try again");
    }
    this.setState({ addBroker: false, addAccountLoading: false });
    this.resetAccount();
  };

  validateSecurityDescription = () => {
    let secRegex = /(.+)-(.+)-(\d+\/\d+\/\d+)-(\w+)-(\w+)(-.+)?/.test(
      this.state.securityDescription
    );

    let newState = this.state.validationErrors;
    newState = {
      ...this.state.validationErrors,
      securityDescription: !secRegex
    };

    this.setState({
      validationErrors: newState
    });
  };

  handleSubmit = async event => {
    event.preventDefault();
    const data = {
      brokerAccountId: this.state.broker.id,
      consideration: this.state.consideration,
      counterpartyId: this.state.counterpartyId,
      couponRate: this.state.couponRate,
      exposure: this.state.exposure,
      fundId: this.state.fundId,
      isin: this.state.isin,
      maturityDate: this.state.maturityDate,
      nominal: this.state.nominal,
      note: this.state.note,
      price: this.state.price,
      processType: this.state.processType,
      reference: this.state.reference,
      repoCheckSum: this.state.repoCheckSum,
      repoDuration: this.state.repoDuration,
      securityDescription: this.state.securityDescription,
      settlementDate: this.state.settlementDate,
      tenor: this.state.tenor,
      tradeDate: this.state.tradeDate,
      transactionType: this.state.transactionType,
      yieldRate: this.state.yieldRate
    };

    const editURL = `${process.env.REACT_APP_BASE_URL}/government-security/${this.props.id}`;
    const createURL = `${process.env.REACT_APP_BASE_URL}/government-security`;

    if (!this.state.validationErrors.securityDescription) {
      try {
        this.setState({ isSubmitting: true });
        const res = await axios({
          method: this.props.editMode ? "PUT" : "POST",
          url: this.props.editMode ? editURL : createURL,
          data: data,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.token}`
          }
        });
        const { status, data: result } = await res;

        let successMessageEdit = "Treasury details updated successfully.";
        let successMessageCreate = "Treasury creation success.";
        if (status > 199 && status < 299) {
          toast.success(
            `${this.props.editMode ? successMessageEdit : successMessageCreate}`
          );
        }

        if (this.state.approvers.length) {
          let params = {
            id: result.id,
            instructionType: "government-security",
            token: this.props.token,
            approvers: this.state.approvers,
            // kind: "PRE_TRADE"
            kind: this.state.isSettlementApproval ? "SETTLEMENT" : "PRE_TRADE"
          };
          await handleCreateApproval(params);
        }

        this.setState({
          isSubmitting: false
        });
      } catch (err) {
        toast.error("Something went wrong. Please try again.");
        this.setState({ isSubmitting: false });
        console.error(err);
      }
    } else {
      toast.warn("Please make sure all values are correct");
    }
  };

  // handleCreateSettlementInstruction = async () => {
  //   let newApprovers = [
  //     ...new Set(
  //       this.props.location.state.approvers.map(approver => approver.id)
  //     )
  //   ];
  //   if (newApprovers.length) {
  //     let params = {
  //       id: this.props.id,
  //       instructionType: "government-security",
  //       token: this.props.token,
  //       approvers: newApprovers,
  //       kind: "SETTLEMENT"
  //     };
  //     await handleCreateApproval(params);
  //   }
  // };

  render() {
    const isApprover = this.props.user.role === "APPROVER";
    const displayApprovers = showApprovers(
      this.state.status,
      this.state.isSettlementApproval
    );

    return (
      <React.Fragment>
        <Card className="p-4rem flex-column">
          {this.state.loaded ? (
            <div style={{ width: "70%" }}>
              <h3>
                {this.props.editMode
                  ? "Edit Treasury Instruction"
                  : "Create Treasury Instruction"}
              </h3>

              <form onSubmit={this.handleSubmit}>
                <h4 className="mb-2rem">Select Account</h4>
                <select
                  onChange={this.handleFund}
                  className="form-input form-input--lg"
                  name="fundId"
                  // required={this.state.isSettlementApproval}
                  disabled={isApprover}
                  value={this.state.fundId}
                >
                  <option value="">Select a fund</option>
                  {this.props.funds
                    ? this.props.funds.map(fund => (
                        <option key={fund.id} value={fund.id}>
                          {fund.name}
                        </option>
                      ))
                    : null}
                </select>
                <div className="row mt-2rem mb-2rem">
                  <div className="column">
                    <label className="mb-1rem">CSD Account Number</label>
                    <input
                      className="form-input form-input--lg"
                      type="text"
                      placeholder=""
                      readOnly
                      disabled={isApprover}
                      value={this.state.csdAccountNumber}
                    />
                  </div>
                  <div className="column">
                    <label className="mb-1rem">Bank Account Number</label>
                    <input
                      className="form-input form-input--lg"
                      type="text"
                      placeholder=""
                      readOnly
                      disabled={isApprover}
                      value={this.state.bankAccountNumber}
                    />
                  </div>
                </div>

                <hr className="mt-4rem mb-4rem" />

                <h4>Details</h4>

                <div className="row mt-2rem mb-2rem">
                  <div className="column">
                    <label className="mb-1rem">Transaction Type</label>
                    <select
                      className="form-input form-input--lg"
                      name="transactionType"
                      onChange={this.handleInput}
                      value={this.state.transactionType}
                      // required={this.state.isSettlementApproval}
                      disabled={isApprover}
                    >
                      <option value="">Select one</option>
                      {Object.keys(TRANSACTION_TYPE).map(key => (
                        <option key={key} value={key}>
                          {TRANSACTION_TYPE[key]}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="column">
                    <label className="mb-1rem">Trade Type</label>
                    <select
                      onChange={this.handleInput}
                      name="processType"
                      value={this.state.processType}
                      className="form-input form-input--lg"
                      // required={this.state.isSettlementApproval}
                      disabled={isApprover}
                    >
                      <option value="">Select one</option>
                      {Object.keys(PROCESS_TYPE).map(key => (
                        <option key={key} value={key}>
                          {PROCESS_TYPE[key]}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="row mb-2rem">
                  <div className="column">
                    <label className="mb-1rem">Tenor</label>
                    <input
                      className="form-input form-input--lg"
                      type="number"
                      max="365"
                      min="1"
                      placeholder=""
                      name="count"
                      value={this.state.tenor.count}
                      onChange={this.handleNested("tenor")}
                      // required={this.state.isSettlementApproval}
                      disabled={isApprover}
                    />
                  </div>
                  <div className="column">
                    <label className="mb-1rem">Period</label>
                    <select
                      onChange={this.handleNested("tenor")}
                      name="period"
                      value={this.state.tenor.period}
                      className="form-input form-input--lg"
                      // required={this.state.isSettlementApproval}
                      disabled={isApprover}
                    >
                      <option value="">Select one</option>
                      {Object.keys(PERIOD).map(key => (
                        <option key={key} value={key}>
                          {PERIOD[key]}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row mt-2rem mb-2rem">
                  <div className="column">
                    <label className="mb-1rem">ISIN</label>
                    <input
                      className="form-input form-input--lg"
                      type="text"
                      placeholder=""
                      value={this.state.isin}
                      // required={this.state.isSettlementApproval}
                      disabled={isApprover}
                      name="isin"
                      onChange={this.handleInput}
                    />
                  </div>
                  <div className="column">
                    <label className="mb-1rem">Security Description</label>
                    <input
                      className="form-input form-input--lg"
                      placeholder="ex: XYZ-BD-01/01/29-A5056-1647"
                      name="securityDescription"
                      value={this.state.securityDescription}
                      // required={this.state.isSettlementApproval}
                      disabled={isApprover}
                      onChange={this.handleInput}
                      onBlur={this.validateSecurityDescription}
                      // onChange={this.handleSecDesc}
                    />
                    {
                      <p
                        style={{
                          visibility: this.state.validationErrors
                            .securityDescription
                            ? "visible"
                            : "hidden",
                          color: "red",
                          fontSize: "70%"
                        }}
                      >
                        Not a valid security description
                      </p>
                    }
                  </div>
                </div>
                <div className="row mt-2rem mb-2rem">
                  <div className="column">
                    <label className="mb-1rem">Yield Rate (%)</label>
                    <input
                      className="form-input form-input--lg"
                      type="text"
                      placeholder=""
                      name="yieldRate"
                      value={this.state.yieldRate}
                      // required={this.state.isSettlementApproval}
                      disabled={isApprover}
                      onChange={this.handleInput}
                    />
                  </div>
                  <div className="column">
                    <label className="mb-1rem">Coupon Rate (%)</label>
                    <input
                      className="form-input form-input--lg"
                      type="number"
                      min="0"
                      max="100"
                      step=".01"
                      placeholder=""
                      name="couponRate"
                      value={this.state.couponRate}
                      // required={this.state.isSettlementApproval}
                      disabled={isApprover}
                      onChange={this.handleInput}
                    />
                  </div>
                </div>
                <div className="row mt-2rem mb-2rem">
                  <div className="column">
                    <label className="mb-1rem">Nominal</label>
                    <input
                      className="form-input form-input--lg"
                      type="number"
                      step="0.01"
                      placeholder=""
                      name="nominal"
                      value={this.state.nominal}
                      // required={this.state.isSettlementApproval}
                      disabled={isApprover}
                      onChange={this.handleInput}
                    />
                  </div>
                  <div className="column">
                    <label className="mb-1rem">Consideration</label>
                    <input
                      className="form-input form-input--lg"
                      type="number"
                      step="0.01"
                      placeholder=""
                      name="consideration"
                      value={this.state.consideration}
                      // required={this.state.isSettlementApproval}
                      disabled={isApprover}
                      onChange={this.handleInput}
                    />
                  </div>
                </div>
                <div className="row mt-2rem mb-2rem">
                  <div className="column">
                    <label className="mb-1rem">Price</label>
                    <input
                      className="form-input form-input--lg"
                      type="number"
                      placeholder=""
                      name="price"
                      step="0.0001"
                      value={this.state.price}
                      // required={this.state.isSettlementApproval}
                      disabled={isApprover}
                      onChange={this.handleInput}
                    />
                  </div>
                  <div className="column">
                    <label className="mb-1rem">Counterparty</label>
                    <select
                      name="counterpartyId"
                      // onChange={this.handleInput}
                      onChange={this.handleCounterparty}
                      value={this.state.counterpartyId}
                      className="form-input form-input--lg"
                      // required={this.state.isSettlementApproval}
                      disabled={isApprover}
                    >
                      <option value="">Select one</option>
                      <option value="addCounter">Add a counterparty...</option>
                      {this.props.counterparties.map(counterparty => (
                        <option key={counterparty.id} value={counterparty.id}>
                          {/* FIXME: What's the name? Moniker or beneficiary? */}
                          {/* {counterparty.beneficiary
                          ? counterparty.beneficiary
                          : counterparty.number} */}
                          {counterparty.beneficiary} - {counterparty.number}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row mt-2rem mb-2rem">
                  <div className="column">
                    <label className="mb-1rem">Maturity Date</label>
                    <DatePicker
                      className="format-input"
                      selected={this.state.maturityDate}
                      // required={this.state.isSettlementApproval}
                      disabled={isApprover}
                      onChange={this.handleDateChange("maturityDate")}
                      dateFormat="dd/MM/yyyy"
                    />
                  </div>
                  <div className="column">
                    <label className="mb-1rem">Settlement Date</label>
                    <DatePicker
                      className="format-input"
                      selected={this.state.settlementDate}
                      // required={this.state.isSettlementApproval}
                      disabled={isApprover}
                      onChange={this.handleDateChange("settlementDate")}
                      dateFormat="dd/MM/yyyy"
                    />
                  </div>
                </div>
                <div className="row mt-2rem mb-2rem">
                  <div className="column">
                    <label className="mb-1rem">Trade Date</label>
                    <DatePicker
                      className="format-input"
                      selected={this.state.tradeDate}
                      // required={this.state.isSettlementApproval}
                      disabled={isApprover}
                      onChange={this.handleDateChange("tradeDate")}
                      dateFormat="dd/MM/yyyy"
                    />
                  </div>
                </div>

                <label>Exposure</label>
                <div className="row mt-1rem mb-2rem">
                  <div className="column">
                    <label>Pre</label>
                    <input
                      className="form-input form-input--lg"
                      type="number"
                      step="0.01"
                      // placeholder="Pre"
                      name="pre"
                      value={this.state.exposure.pre}
                      onChange={this.handleNested("exposure")}
                      disabled={isApprover}
                    />
                  </div>
                  <div className="column">
                    <label>Post</label>
                    <input
                      className="form-input form-input--lg"
                      type="number"
                      step="0.01"
                      // placeholder="Post"
                      name="post"
                      value={this.state.exposure.post}
                      onChange={this.handleNested("exposure")}
                      disabled={isApprover}
                    />
                  </div>
                  <div className="column">
                    <label>Max</label>
                    <input
                      className="form-input form-input--lg"
                      type="number"
                      step="0.01"
                      // placeholder="Max"
                      name="max"
                      value={this.state.exposure.max}
                      onChange={this.handleNested("exposure")}
                      disabled={isApprover}
                    />
                  </div>
                </div>

                {this.state.processType !== "SECONDARY" ? (
                  <React.Fragment>
                    <hr className="mt-4rem mb-4rem" />

                    <h4 className="mt-2rem">Broker Details</h4>

                    <div className="row mt-2rem mb-2rem">
                      <div className="column">
                        <label className="mb-1rem">Account Name</label>
                        <select
                          name="brokerAccountId"
                          className="form-input form-input--lg"
                          onChange={this.handleBroker}
                          value={this.state.broker.id}
                          disabled={isApprover}
                        >
                          <option value="">Select one</option>
                          <option value="addBroker">Add a broker...</option>
                          {this.props.brokers &&
                            this.props.brokers.map(broker => (
                              <option key={broker.id} value={broker.id}>
                                {broker.moniker
                                  ? broker.moniker
                                  : broker.number}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="column">
                        <label className="mb-1rem">Account Number</label>
                        <input
                          className="form-input form-input--lg"
                          type="string"
                          placeholder=""
                          value={this.state.broker.number}
                          readOnly
                          disabled={isApprover}
                        />
                      </div>
                    </div>
                    <div className="row mt-2rem mb-2rem">
                      <div className="column">
                        <label className="mb-1rem">Bank</label>
                        <input
                          className="form-input form-input--lg"
                          type="string"
                          placeholder=""
                          value={this.state.broker.bankName}
                          readOnly
                          disabled={isApprover}
                        />
                      </div>
                      <div className="column">
                        <label className="mb-1rem">Branch</label>
                        <input
                          className="form-input form-input--lg"
                          type="string"
                          placeholder=""
                          value={this.state.broker.branch}
                          readOnly
                          disabled={isApprover}
                        />
                      </div>
                    </div>
                  </React.Fragment>
                ) : null}

                <hr className="mt-4rem mb-4rem" />

                <div className="row mt-2rem mb-2rem">
                  <div className="column">
                    <label className="mb-1rem">Note</label>
                    <textarea
                      className="form-textarea"
                      name="note"
                      value={this.state.note}
                      onChange={this.handleInput}
                      cols="30"
                      rows="6"
                      disabled={isApprover}
                    ></textarea>
                  </div>
                </div>

                {!isApprover && (
                  <React.Fragment>
                    <hr className="mt-4rem mb-4rem" />

                    {displayApprovers && (
                      <React.Fragment>
                        <h4 className="mt-2rem">Co-signers:</h4>
                        {this.props.approvers
                          ? this.props.approvers.map(approver => (
                              <div key={approver.id}>
                                <label htmlFor="">
                                  <input
                                    disabled={
                                      this.props.user.id === approver.id
                                    }
                                    type="checkbox"
                                    name=""
                                    id=""
                                    value={approver.id}
                                    onChange={this.handleCheckbox}
                                  />
                                  <span className="ml-1rem">
                                    {approver.firstName} {approver.lastName}
                                  </span>
                                </label>
                              </div>
                            ))
                          : null}
                      </React.Fragment>
                    )}

                    <label htmlFor="">
                      <input
                        className="mt-4rem"
                        type="checkbox"
                        value={this.state.isSettlementApproval}
                        name="isSettlementApproval"
                        onChange={this.handleInput}
                      />
                      <span className="ml-1rem">
                        Create settlement approval
                      </span>
                    </label>

                    <hr className="mt-4rem mb-4rem" />

                    <div className="mt-2rem"></div>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={this.state.isSubmitting}
                    >
                      {this.props.editMode
                        ? "Save and Update"
                        : "Create Instruction"}
                    </Button>
                    {this.state.message ? (
                      <p style={{ color: "red" }}>{this.state.message}</p>
                    ) : null}
                  </React.Fragment>
                )}
              </form>
            </div>
          ) : (
            "Please wait..."
          )}
        </Card>
        <Modal
          visible={this.state.addCounterparty || this.state.addBroker}
          title={this.state.addCounterparty ? "Add counterparty" : "Add broker"}
          onCancel={
            this.state.addCounterparty
              ? () => this.setState({ addCounterparty: false })
              : () => this.setState({ addBroker: false })
          }
          onOk={
            this.state.addCounterparty
              ? event => this.handleCreateCounterparty(event)
              : event => this.handleCreateBroker(event)
          }
          confirmLoading={this.state.addAccountLoading}
        >
          <div>
            Beneficiary:{" "}
            <input
              name="beneficiary"
              value={this.state.beneficiary}
              onChange={this.handleInput}
            />
          </div>
          <div>
            Bank name:{" "}
            <input
              name="bankName"
              value={this.state.bankName}
              onChange={this.handleInput}
            />
          </div>
          <div>
            Swift code:{" "}
            <input
              name="swiftCode"
              value={this.state.swiftCode}
              onChange={this.handleInput}
            />
          </div>
          <div>
            Number:{" "}
            <input
              name="number"
              value={this.state.number}
              onChange={this.handleInput}
            />
          </div>
          <div>
            Contact person:{" "}
            <input
              name="contactPerson"
              value={this.state.contactPerson}
              onChange={this.handleInput}
            />
          </div>
          <div>
            Moniker:{" "}
            <input
              name="moniker"
              value={this.state.moniker}
              onChange={this.handleInput}
            />
          </div>
          <div>
            Branch:{" "}
            <input
              name="branch"
              value={this.state.branch}
              onChange={this.handleInput}
            />
          </div>
          <div>
            Address:{" "}
            <input
              name="address"
              value={this.state.address}
              onChange={this.handleInput}
            />
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default connect(
  state => ({
    token: state.token,
    funds: state.funds,
    approvers: state.approvers,
    csdAccounts: state.csdAccounts,
    bankAccounts: state.bankAccounts,
    user: state.user,
    brokers: state.bankAccounts.filter(bankAccount => {
      return bankAccount.isBroker;
    }),
    counterparties: state.bankAccounts.filter(bankAccount => {
      return bankAccount.isCounterparty;
    })
  }),
  dispatch => ({
    unsetTreasuries: () => dispatch(unsetTreasuries()),
    setFunds: funds => dispatch(setFunds(funds))
  })
)(Treasury);
