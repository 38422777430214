import { actionTypes } from "constants/index";

export const csdAccounts = (state = [], action) => {
  switch (action.type) {
    case actionTypes.SET_CSD:
      return action.payload;
    default:
      return state;
  }
};

export const bankAccounts = (state = [], action) => {
  switch (action.type) {
    case actionTypes.SET_BANKS:
      return action.payload;
    default:
      return state;
  }
};
