import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import DatePicker from "react-datepicker";
import { parseISO } from "date-fns";
import { toast } from "react-toastify";
import { Button, Modal } from "antd";

import { E_PROCESS_TYPE, TRANSACTION_TYPE } from "constants/index";
import { unsetEquities } from "actions/equities";
import { getBanks } from "utils/get-thingy";
import { handleCreateApproval, showApprovers } from "utils/approvals";

import { Card } from "components/Card";

const initialState = {
  fundId: "",
  csdAccountNumber: "",
  bankAccountNumber: "",
  transactionType: "",
  processType: "",
  description: "",
  quantity: "",
  settlementAmount: "",
  tradeDate: "",
  settlementDate: "",
  note: "",
  exposure: {
    pre: "",
    post: "",
    max: ""
  },
  potentialReturns: "",
  fairPrice: "",
  unitPrice: "",
  broker: {
    bankName: "",
    number: "",
    branch: "",
    moniker: ""
  },
  ipo: {
    number: "",
    bankName: "",
    branch: ""
  },
  approvers: [],
  isSettlementApproval: false,
  message: "",
  securityDescriptions: [],

  // Add broker
  addBroker: false,
  addAccountLoading: false,
  beneficiary: "",
  bankName: "",
  swiftCode: "",
  number: "",
  moniker: "",
  contactPerson: "",
  branch: "",
  address: ""
};

class Equity extends React.Component {
  state = {
    ...initialState,
    loaded: false,
    isSubmitting: false
  };

  async componentDidMount() {
    const getSecDesc = async () => {
      let res = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/actual-equity`,
        headers: {
          Authorization: `Bearer ${this.props.token}`
        },
        method: "GET"
      });
      let { data } = res;
      this.setState({ securityDescriptions: data });
    };
    getSecDesc();

    if (this.props.editMode) {
      const setCSD = async id => {
        if (this.props.funds) {
          let { csdAccountId } = await this.props.funds.find(
            fund => Number(fund.id) === Number(id)
          );
          let { number } = await this.props.csdAccounts.find(
            csdAccount => Number(csdAccount.id) === Number(csdAccountId)
          );
          this.setState({
            csdAccountNumber: number
          });
        }
      };

      const setBankAccount = async id => {
        if (this.props.funds) {
          let { bankAccountId } = await this.props.funds.find(
            fund => Number(fund.id) === Number(id)
          );
          let { number } = await this.props.bankAccounts.find(
            bankAccount => Number(bankAccount.id) === Number(bankAccountId)
          );
          this.setState({
            bankAccountNumber: number
          });
        }
      };

      const setBroker = async id => {
        if (this.props.brokers) {
          let broker = await this.props.brokers.find(
            broker => Number(broker.id) === Number(id)
          );
          if (broker) {
            this.setState({
              broker
            });
          } else {
            this.setState({
              broker: {}
            });
          }
        }
      };

      const setIpo = async id => {
        if (this.props.ipos) {
          let ipo = await this.props.ipos.find(
            ipo => Number(ipo.id) === Number(id)
          );
          if (ipo) {
            this.setState({
              ipo
            });
          } else {
            this.setState({
              ipo: {}
            });
          }
        }
      };

      const getFund = async () => {
        let { data: result } = await axios({
          headers: {
            Authorization: `Bearer ${this.props.token}`
          },
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/equity/${this.props.id}`
        });
        console.error(result);
        const data = {
          ...result,
          settlementDate: parseISO(result.settlementDate),
          tradeDate: parseISO(result.tradeDate),
          maturityDate: parseISO(result.maturityDate)
        };
        await this.setState({
          ...data
        });
        await setCSD(result.fundId);
        await setBankAccount(result.fundId);
        await setBroker(result.brokerAccountId);
        await setIpo(result.ipoBankAccountId);
        await this.setState({ loaded: true });
      };

      await getFund();
    } else {
      this.setState({
        ...initialState,
        loaded: true
      });
    }
  }

  handleInput = event => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value });
  };

  handleFund = event => {
    let { name, value } = event.target;
    this.setState({ [name]: value });

    if (event.target.value) {
      let { csdAccountId } = this.props.funds.find(
        fund => Number(fund.id) === Number(value)
      );
      let csdAccountNumber = this.props.csdAccounts.find(
        csdAccount => Number(csdAccount.id) === Number(csdAccountId)
      ).number;

      let { bankAccountId } = this.props.funds.find(
        fund => Number(fund.id) === Number(value)
      );
      let bankAccountNumber = this.props.bankAccounts.find(
        bankAccount => Number(bankAccount.id) === Number(bankAccountId)
      ).number;

      this.setState({
        csdAccountNumber,
        bankAccountNumber
      });
    } else {
      this.setState({
        csdAccountNumber: "",
        bankAccountNumber: ""
      });
    }
  };

  handleNested = stateName => event => {
    let { name, value } = event.target;
    this.setState({
      ...this.state,
      [stateName]: {
        ...this.state[stateName],
        [name]: value
      }
    });
  };

  handleBroker = event => {
    let { value } = event.target;
    if (value === "addBroker") {
      this.setState({ addBroker: true });
    } else if (value !== "") {
      let broker = this.props.brokers.find(
        broker => Number(broker.id) === Number(value)
      );
      this.setState({
        broker
      });
    } else {
      this.setState({
        broker: {
          bankName: "",
          number: "",
          branch: "",
          moniker: ""
        }
      });
    }

    // if (value) {
    //   let broker = this.props.brokers.find(
    //     broker => Number(broker.id) === Number(value)
    //   );
    //   this.setState({
    //     broker
    //   });
    // } else {
    //   this.setState({
    //     broker: {}
    //   });
    // }
  };

  handleIpo = event => {
    let { value } = event.target;

    if (value) {
      let ipo = this.props.ipos.find(ipo => Number(ipo.id) === Number(value));
      this.setState({
        ipo
      });
    } else {
      this.setState({
        ipo: {}
      });
    }
  };

  handleCheckbox = event => {
    const approvers = this.state.approvers;
    let index;
    if (event.target.checked) {
      approvers.push(event.target.value);
    } else {
      index = approvers.indexOf(event.target.value);
      approvers.splice(index, 1);
    }
    this.setState({ approvers });
  };

  handleDateChange = stateName => date => {
    this.setState({
      [stateName]: date
    });
  };

  resetAccount = () => {
    this.setState({
      beneficiary: "",
      bankName: "",
      swiftCode: "",
      number: "",
      moniker: "",
      contactPerson: "",
      branch: "",
      address: ""
    });
  };

  handleCreateBroker = async event => {
    event.preventDefault();
    const data = {
      beneficiary: this.state.beneficiary,
      bankName: this.state.bankName,
      swiftCode: this.state.swiftCode,
      number: this.state.number,
      moniker: this.state.moniker,
      contactPerson: this.state.contactPerson,
      branch: this.state.branch,
      address: this.state.address,
      isBroker: true
    };

    this.setState({ addAccountLoading: true });
    try {
      await axios({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/bank-account`,
        data: data,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.token}`
        }
      });
      toast.success("Broker added");
      getBanks(this.props.token);
    } catch (err) {
      console.error(err);
      toast.error("Something went wrong. Please try again");
    }
    this.setState({ addBroker: false, addAccountLoading: false });
    this.resetAccount();
  };

  handleSubmit = async event => {
    event.preventDefault();
    const data = {
      fundId: this.state.fundId,
      transactionType: this.state.transactionType,
      processType: this.state.processType,
      description: this.state.description,
      quantity: this.state.quantity,
      settlementAmount: this.state.settlementAmount,
      tradeDate: this.state.tradeDate,
      settlementDate: this.state.settlementDate,
      ipoBankAccountId: this.state.ipo.id,
      note: this.state.note,
      exposure: this.state.exposure,
      potentialReturns: this.state.potentialReturns,
      fairPrice: this.state.fairPrice,
      unitPrice: this.state.unitPrice,
      broker: this.state.broker.moniker
        ? this.state.broker.moniker
        : this.state.broker.number
    };

    const editURL = `${process.env.REACT_APP_BASE_URL}/equity/${this.props.id}`;
    const createURL = `${process.env.REACT_APP_BASE_URL}/equity`;

    try {
      this.setState({ isSubmitting: true });
      const res = axios({
        method: this.props.editMode ? "PUT" : "POST",
        url: this.props.editMode ? editURL : createURL,
        data: data,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.token}`
        }
      });
      const { status, data: result } = await res;

      let successMessageEdit = "Equity details updated successfully.";
      let successMessageCreate = "Equity creation success.";
      if (status > 199 && status < 299) {
        toast.success(
          `${this.props.editMode ? successMessageEdit : successMessageCreate}`
        );
      }

      if (this.state.approvers.length) {
        let params = {
          id: result.id,
          instructionType: "equity",
          token: this.props.token,
          approvers: this.state.approvers,
          kind: this.state.isSettlementApproval ? "SETTLEMENT" : "PRE_TRADE"
        };
        await handleCreateApproval(params);
      }

      this.props.unsetEquities();
      if (this.props.editMode) {
        this.setState({
          isSubmitting: false
        });
      } else {
        this.setState({
          ...initialState,
          isSubmitting: false
        });
      }
    } catch (err) {
      toast.error("Something went wrong. Please try again.");
      this.setState({ isSubmitting: false });
      console.error(err);
    }
  };
  render() {
    const isIpo = this.state.processType === "PRIMARY" ? "IPO " : null;
    const isApprover = this.props.user.role === "APPROVER";
    const displayApprovers = showApprovers(
      this.state.status,
      this.state.isSettlementApproval
    );

    return (
      <React.Fragment>
        <Card className="p-4rem flex-column">
          {this.state.loaded ? (
            <div style={{ width: "70%" }}>
              <h3>
                {this.props.editMode
                  ? "Edit Equity"
                  : "Create Equity Instruction"}
              </h3>
              <form onSubmit={this.handleSubmit}>
                <h4 className="mb-2rem">Select Account</h4>
                <select
                  onChange={this.handleFund}
                  name="fundId"
                  value={this.state.fundId}
                  disabled={isApprover}
                  className="form-input form-input--lg"
                >
                  <option value="">Select a fund</option>
                  {this.props.funds
                    ? this.props.funds.map(fund => (
                        <option key={fund.id} value={fund.id}>
                          {fund.name}
                        </option>
                      ))
                    : null}
                </select>
                <div className="row mt-2rem mb-2rem">
                  <div className="column">
                    <label className="mb-1rem">CSD Account Number</label>
                    <input
                      className="form-input form-input--lg"
                      type="text"
                      placeholder=""
                      value={this.state.csdAccountNumber}
                      readOnly
                      disabled={isApprover}
                    />
                  </div>
                  <div className="column">
                    <label className="mb-1rem">Bank Account Number</label>
                    <input
                      className="form-input form-input--lg"
                      type="text"
                      placeholder=""
                      value={this.state.bankAccountNumber}
                      readOnly
                      disabled={isApprover}
                    />
                  </div>
                </div>

                <hr className="mt-4rem mb-4rem" />

                <h4>Details</h4>

                <div className="row mt-2rem mb-2rem">
                  <div className="column">
                    <label className="mb-1rem">Market</label>
                    <select
                      onChange={this.handleInput}
                      value={this.state.processType}
                      name="processType"
                      disabled={isApprover}
                      className="form-input form-input--lg"
                    >
                      <option value="">Select</option>
                      {Object.keys(E_PROCESS_TYPE).map(key => (
                        <option key={key} value={key}>
                          {E_PROCESS_TYPE[key]}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="column">
                    <label className="mb-1rem">Transaction Type</label>
                    <select
                      className="form-input form-input--lg"
                      name="transactionType"
                      onChange={this.handleInput}
                      value={this.state.transactionType}
                      disabled={isApprover}
                    >
                      <option value="">Select one</option>
                      {Object.keys(TRANSACTION_TYPE).map(key => (
                        <option value={key} key={key}>
                          {TRANSACTION_TYPE[key]}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row mt-2rem mb-2rem">
                  <div className="column">
                    <label className="mb-1rem">
                      Equity / Security Description
                    </label>
                    <select
                      className="form-input form-input--lg"
                      name="description"
                      onChange={this.handleInput}
                      value={this.state.description}
                      disabled={isApprover}
                    >
                      <option value="">Select one</option>
                      {this.state.securityDescriptions.map(
                        securityDescription => (
                          <option
                            key={securityDescription.id}
                            value={securityDescription.description}
                          >
                            {securityDescription.description}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </div>
                <div className="row mt-2rem mb-2rem">
                  <div className="column">
                    <label className="mb-1rem">Trade Date</label>
                    <DatePicker
                      className="format-input"
                      selected={this.state.tradeDate}
                      disabled={isApprover}
                      onChange={this.handleDateChange("tradeDate")}
                      dateFormat="dd/MM/yyyy"
                    />
                  </div>
                  <div className="column">
                    <label className="mb-1rem">Settlement Date</label>
                    <DatePicker
                      className="format-input"
                      selected={this.state.settlementDate}
                      disabled={isApprover}
                      onChange={this.handleDateChange("settlementDate")}
                      dateFormat="dd/MM/yyyy"
                    />
                  </div>
                </div>
                <div className="row mt-2rem mb-2rem">
                  <div className="column">
                    <label className="mb-1rem">Settlement Amount</label>
                    <input
                      className="form-input form-input--lg"
                      type="number"
                      step="0.01"
                      placeholder=""
                      value={this.state.settlementAmount}
                      name="settlementAmount"
                      onChange={this.handleInput}
                      disabled={isApprover}
                    />
                  </div>
                  <div className="column">
                    <label className="mb-1rem">Quantity</label>
                    <input
                      className="form-input form-input--lg"
                      type="number"
                      step="0.01"
                      placeholder=""
                      value={this.state.quantity}
                      name="quantity"
                      onChange={this.handleInput}
                      disabled={isApprover}
                    />
                  </div>
                </div>

                <label>Exposure</label>
                <div className="row mt-1rem mb-2rem">
                  <div className="column">
                    <label>Pre</label>
                    <input
                      className="form-input form-input--lg"
                      type="number"
                      step="0.01"
                      name="pre"
                      value={this.state.exposure.pre}
                      onChange={this.handleNested("exposure")}
                      disabled={isApprover}
                    />
                  </div>
                  <div className="column">
                    <label>Post</label>
                    <input
                      className="form-input form-input--lg"
                      type="number"
                      step="0.01"
                      name="post"
                      value={this.state.exposure.post}
                      onChange={this.handleNested("exposure")}
                      disabled={isApprover}
                    />
                  </div>
                  <div className="column">
                    <label>Max</label>
                    <input
                      className="form-input form-input--lg"
                      type="number"
                      step="0.01"
                      name="max"
                      value={this.state.exposure.max}
                      onChange={this.handleNested("exposure")}
                      disabled={isApprover}
                    />
                  </div>
                </div>

                <hr className="mt-4rem mb-4rem" />

                <div className="row mt-2rem mb-2rem">
                  <div className="column">
                    <label className="mb-1rem">Unit Price</label>
                    <input
                      className="form-input form-input--lg"
                      type="number"
                      step="0.01"
                      placeholder=""
                      value={this.state.unitPrice}
                      name="unitPrice"
                      onChange={this.handleInput}
                      disabled={isApprover}
                    />
                  </div>
                </div>
                <div className="row mt-2rem mb-2rem">
                  <div className="column">
                    <label className="mb-1rem">Fair Price</label>
                    <input
                      className="form-input form-input--lg"
                      type="number"
                      step="0.01"
                      placeholder=""
                      value={this.state.fairPrice}
                      name="fairPrice"
                      onChange={this.handleInput}
                      disabled={isApprover}
                    />
                  </div>
                  <div className="column">
                    <label className="mb-1rem">Potential Returns</label>
                    <input
                      className="form-input form-input--lg"
                      type="number"
                      step="0.01"
                      placeholder=""
                      value={this.state.potentialReturns}
                      name="potentialReturns"
                      onChange={this.handleInput}
                      disabled={isApprover}
                    />
                  </div>
                </div>

                <hr className="mt-4rem mb-4rem" />

                <div className="row mt-2rem mb-2rem">
                  <div className="column">
                    <label className="mb-1rem">Broker Name</label>
                    <select
                      name="broker"
                      className="form-input form-input--lg"
                      onChange={this.handleBroker}
                      value={this.state.broker.id}
                      disabled={isApprover}
                    >
                      <option value="">Select one</option>
                      <option value="addBroker">Add a broker...</option>
                      {this.props.brokers &&
                        this.props.brokers.map(broker => (
                          <option key={broker.id} value={broker.id}>
                            {broker.moniker ? broker.moniker : broker.number}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>

                {this.state.processType === "SECONDARY" && (
                  <React.Fragment key="">
                    <div className="row mt-2rem mb-2rem">
                      {/* <div className="column">
                        <label className="mb-1rem">{isIpo}Account Name</label>
                        <select
                          name="ipo"
                          className="form-input form-input--lg"
                          onChange={this.handleIpo}
                          value={this.state.broker.moniker}
                          disabled={isApprover}
                        >
                          <option value="">Select one</option>
                          {this.props.ipos
                            ? this.props.ipos.map(ipo => (
                                <option key={ipo.id} value={ipo.id}>
                                  {ipo.moniker}
                                </option>
                              ))
                            : null}
                        </select>
                      </div> */}
                      <div className="column">
                        <label className="mb-1rem">{isIpo}Account Number</label>
                        <input
                          className="form-input form-input--lg"
                          type="text"
                          placeholder=""
                          value={this.state.broker.number}
                          name="ipoNumber"
                          readOnly
                          disabled={isApprover}
                        />
                      </div>
                    </div>
                    <div className="row mt-2rem mb-2rem">
                      <div className="column">
                        <label className="mb-1rem">{isIpo}Account Bank</label>
                        <input
                          className="form-input form-input--lg"
                          type="text"
                          placeholder=""
                          value={this.state.broker.bankName}
                          name="ipoBank"
                          readOnly
                          disabled={isApprover}
                        />
                      </div>
                      <div className="column">
                        <label className="mb-1rem">{isIpo}Account Branch</label>
                        <input
                          className="form-input form-input--lg"
                          type="text"
                          placeholder=""
                          value={this.state.broker.branch}
                          name="ipoBranch"
                          readOnly
                          disabled={isApprover}
                        />
                      </div>
                    </div>
                  </React.Fragment>
                )}

                <hr className="mt-4rem mb-4rem" />

                <div className="row mt-2rem mb-2rem">
                  <div className="column">
                    <label className="mb-1rem">Note</label>
                    <textarea
                      className="form-textarea"
                      name="note"
                      value={this.state.note}
                      onChange={this.handleInput}
                      cols="30"
                      rows="6"
                      disabled={isApprover}
                    ></textarea>
                  </div>
                </div>

                {!isApprover && (
                  <React.Fragment>
                    <hr className="mt-4rem mb-4rem" />

                    {displayApprovers && (
                      <React.Fragment>
                        <h4 className="mt-2rem">
                          Co-signers: Select two individuals to co-sign
                        </h4>

                        {this.props.approvers
                          ? this.props.approvers.map(approver => (
                              <div key={approver.id}>
                                <label htmlFor="">
                                  <input
                                    disabled={
                                      this.props.user.id === approver.id
                                    }
                                    type="checkbox"
                                    onChange={this.handleCheckbox}
                                    value={approver.id}
                                    name=""
                                    id=""
                                  />
                                  <span className="ml-1rem">
                                    {approver.firstName} {approver.lastName}
                                  </span>
                                </label>
                              </div>
                            ))
                          : null}
                      </React.Fragment>
                    )}

                    <label htmlFor="">
                      <input
                        className="mt-4rem"
                        type="checkbox"
                        value={this.state.isSettlementApproval}
                        name="isSettlementApproval"
                        onChange={this.handleInput}
                      />
                      <span className="ml-1rem">
                        Create settlement approval
                      </span>
                    </label>

                    <hr className="mt-4rem mb-4rem" />

                    <div className="mt-2rem"></div>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={this.state.isSubmitting}
                    >
                      {this.props.editMode
                        ? "Save and Update"
                        : "Create Instruction"}
                    </Button>
                    {this.state.message ? (
                      <p style={{ color: "red" }}>{this.state.message}</p>
                    ) : null}
                  </React.Fragment>
                )}
              </form>
            </div>
          ) : (
            "Please wait..."
          )}
        </Card>
        <Modal
          visible={this.state.addBroker}
          title="Add broker"
          onCancel={() => this.setState({ addBroker: false })}
          onOk={this.handleCreateBroker}
          confirmLoading={this.state.addAccountLoading}
        >
          <div>
            Beneficiary:{" "}
            <input
              name="beneficiary"
              value={this.state.beneficiary}
              onChange={this.handleInput}
            />
          </div>
          <div>
            Bank name:{" "}
            <input
              name="bankName"
              value={this.state.bankName}
              onChange={this.handleInput}
            />
          </div>
          <div>
            Swift code:{" "}
            <input
              name="swiftCode"
              value={this.state.swiftCode}
              onChange={this.handleInput}
            />
          </div>
          <div>
            Number:{" "}
            <input
              name="number"
              value={this.state.number}
              onChange={this.handleInput}
            />
          </div>
          <div>
            Contact person:{" "}
            <input
              name="contactPerson"
              value={this.state.contactPerson}
              onChange={this.handleInput}
            />
          </div>
          <div>
            Moniker:{" "}
            <input
              name="moniker"
              value={this.state.moniker}
              onChange={this.handleInput}
            />
          </div>
          <div>
            Branch:{" "}
            <input
              name="branch"
              value={this.state.branch}
              onChange={this.handleInput}
            />
          </div>
          <div>
            Address:{" "}
            <input
              name="address"
              value={this.state.address}
              onChange={this.handleInput}
            />
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default connect(
  state => ({
    token: state.token,
    funds: state.funds,
    approvers: state.approvers,
    csdAccounts: state.csdAccounts,
    bankAccounts: state.bankAccounts,
    user: state.user,
    brokers: state.bankAccounts.filter(bankAccount => {
      return bankAccount.isBroker;
    }),
    counterparties: state.bankAccounts.filter(bankAccount => {
      return bankAccount.isCounterparty;
    }),
    ipos: state.bankAccounts.filter(bankAccount => {
      return bankAccount.isIpo;
    })
  }),
  dispatch => ({
    unsetEquities: () => dispatch(unsetEquities())
  })
)(Equity);
