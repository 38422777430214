import React from "react";
import { connect } from "react-redux";
import { Link, navigate } from "@reach/router";
import NavLink from "utils/NavLink";
import { Menu, Dropdown } from "antd";

import { unsetToken } from "actions/authUser";
import logo from "images/asset-mgt.png";

const Navigation = ({ unsetToken, user }) => {
  const handleLogout = () => {
    unsetToken();
    navigate("/");
  };

  const menu = (
    <Menu>
      {user.role === "ADMIN" && (
        <Menu.Item>
          <Link to="/admin">Admin</Link>
        </Menu.Item>
      )}
      <Menu.Item>
        <span onClick={handleLogout} target="_blank" rel="noopener noreferrer">
          Logout
        </span>
      </Menu.Item>
    </Menu>
  );
  return (
    <nav className="navigation">
      <Link to="/">
        <img className="logo--md" src={logo} alt="IC Asset Managers" />
      </Link>
      <ul className="navigation__items">
        <NavLink to="/treasuries">Treasuries</NavLink>
        <NavLink to="/fixed-deposits">Fixed Deposits</NavLink>
        <NavLink to="/issuer-side-fixed-deposits">
          Issuer-Side Fixed Deposits
        </NavLink>
        <NavLink to="/disinvestments">Disinvestments</NavLink>
        <NavLink to="/withdrawals">Withdrawals</NavLink>
        <NavLink to="/equities">Equities</NavLink>
      </ul>
      <div className="pointer navigation__item">
        <Dropdown overlay={menu}>
          <span className="ant-dropdown-link">
            {user.firstName} {user.lastName}
          </span>
        </Dropdown>
      </div>
    </nav>
  );
};

export default connect(
  state => ({
    user: state.user
  }),
  dispatch => ({
    unsetToken: () => dispatch(unsetToken())
  })
)(Navigation);
