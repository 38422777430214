import React from "react";
// import axios from "axios";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Location } from "@reach/router";

import { fetchFunds } from "actions/funds";

import { getInitialData } from "utils/get-thingy";
import AuthedApp from "./authed-app";
import { Login } from "screens/Login";

toast.configure({
  autoClose: 2000
});

const App = ({ token, fetchFunds }) => {
  React.useEffect(() => {
    if (token) {
      fetchFunds(token);
      getInitialData(token);
    }
  }, [token, fetchFunds]);

  return (
    <React.Fragment>
      {token ? (
        <AuthedApp />
      ) : (
        <Location>{({ location }) => <Login location={location} />}</Location>
      )}
    </React.Fragment>
  );
};

export default connect(
  state => ({
    token: state.token
  }),
  dispatch => ({
    fetchFunds: token => dispatch(fetchFunds(token))
  })
)(App);
