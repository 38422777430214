import React from "react";

import { Card } from "components/Card";

const CreateUser = () => {
  const [email, setEmail] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [role, setRole] = React.useState("");

  const handleChange = setValue => event => {
    setValue(event.target.value);
  };

  const onSubmit = event => {
    event.preventDefault();
    // const data = {
    //   email,
    //   firstName,
    //   lastName,
    //   role
    // };
  };

  return (
    <Card className="p-4rem flex-column">
      <form onSubmit={onSubmit} style={{ width: "70%" }}>
        <h4>Create User</h4>

        <div className="row mt-2rem mb-2rem">
          <div className="column">
            <label className="mb-1rem">First Name</label>
            <input
              className="form-input form-input--lg"
              type="text"
              placeholder=""
              value={firstName}
              onChange={handleChange(setFirstName)}
            />
          </div>
          <div className="column">
            <label className="mb-1rem">Last Name</label>
            <input
              className="form-input form-input--lg"
              type="text"
              placeholder=""
              value={lastName}
              onChange={handleChange(setLastName)}
            />
          </div>
        </div>

        <div className="row mt-2rem mb-2rem">
          <div className="column">
            <label className="mb-1rem">User Email</label>
            <input
              className="form-input form-input--lg"
              type="text"
              placeholder=""
              value={email}
              onChange={handleChange(setEmail)}
            />
          </div>
          <div className="column">
            <label className="mb-1rem">User Role</label>
            <select
              value={role}
              onChange={handleChange(setRole)}
              className="form-input form-input--lg"
            >
              <option value="">Select user type</option>
              <option value="1st">2</option>
              <option value="1st">3</option>
              <option value="1st">4</option>
              <option value="1st">5</option>
              <option value="1st">6</option>
            </select>
          </div>
        </div>

        <button>Create</button>
      </form>
    </Card>
  );
};

export default CreateUser;
