import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import DatePicker from "react-datepicker";
import { parseISO } from "date-fns";
import { toast } from "react-toastify";
import { Button } from "antd";

import { PERIOD } from "constants/index";
import { unsetDisinvestments } from "actions/disinvestments";
import { setFunds } from "actions/funds";
import { handleCreateApproval, showApprovers } from "utils/approvals";

import { Card } from "components/Card";

const initialState = {
  csdAccountNumber: "",
  bankAccountNumber: "",
  effectiveDate: "",
  fundId: "",
  issuerId: "",
  maturityDate: "",
  valueDate: "",
  marketValue: "",
  note: "",
  principalAmount: "",
  rate: "",
  tenor: {
    count: "",
    period: ""
  },
  approvers: [],
  isSettlementApproval: false
  // messageId: null
};

class Disinvestment extends React.Component {
  state = {
    ...initialState,
    loaded: false,
    isSubmitting: false
  };

  async componentDidMount() {
    if (this.props.editMode) {
      const getFunds = async () => {
        let response = await axios({
          headers: {
            Authorization: `Bearer ${this.props.token}`
          },
          url: `${process.env.REACT_APP_BASE_URL}/fund`,
          method: "GET"
        });
        let { data } = await response;
        await setFunds(data);
      };
      await getFunds();
      const setCSD = async id => {
        if (this.props.funds) {
          let { csdAccountId } = await this.props.funds.find(
            fund => Number(fund.id) === Number(id)
          );
          let { number } = await this.props.csdAccounts.find(
            csdAccount => Number(csdAccount.id) === Number(csdAccountId)
          );
          this.setState({
            csdAccountNumber: number
          });
        }
      };

      const setBankAccount = async id => {
        if (this.props.funds) {
          let { bankAccountId } = await this.props.funds.find(
            fund => Number(fund.id) === Number(id)
          );
          let { number } = await this.props.bankAccounts.find(
            bankAccount => Number(bankAccount.id) === Number(bankAccountId)
          );
          this.setState({
            bankAccountNumber: number
          });
        }
      };

      const getFund = async () => {
        let { data: result } = await axios({
          headers: {
            Authorization: `Bearer ${this.props.token}`
          },
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/disinvestment/${this.props.id}`
        });
        const data = {
          ...result,
          effectiveDate: parseISO(result.effectiveDate),
          maturityDate: parseISO(result.maturityDate),
          valueDate: parseISO(result.valueDate)
        };
        await this.setState({
          ...data
        });
        await setCSD(result.fundId);
        await setBankAccount(result.fundId);
        await this.setState({ loaded: true });
      };

      await getFund();
    } else {
      this.setState({
        ...initialState,
        loaded: true
      });
    }
  }

  handleInput = event => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value });
  };

  handleFund = event => {
    let { name, value } = event.target;
    this.setState({ [name]: value });

    if (event.target.value) {
      let { csdAccountId } = this.props.funds.find(
        fund => Number(fund.id) === Number(value)
      );
      let csdAccountNumber = this.props.csdAccounts.find(
        csdAccount => Number(csdAccount.id) === Number(csdAccountId)
      ).number;

      let { bankAccountId } = this.props.funds.find(
        fund => Number(fund.id) === Number(value)
      );
      let bankAccountNumber = this.props.bankAccounts.find(
        bankAccount => Number(bankAccount.id) === Number(bankAccountId)
      ).number;

      this.setState({
        csdAccountNumber,
        bankAccountNumber
      });
    } else {
      this.setState({
        csdAccountNumber: "",
        bankAccountNumber: ""
      });
    }
  };

  handleNested = stateName => event => {
    let { name, value } = event.target;
    this.setState({
      ...this.state,
      [stateName]: {
        ...this.state[stateName],
        [name]: value
      }
    });
  };

  handleCheckbox = event => {
    const approvers = this.state.approvers;
    let index;
    if (event.target.checked) {
      approvers.push(event.target.value);
    } else {
      index = approvers.indexOf(event.target.value);
      approvers.splice(index, 1);
    }
    this.setState({ approvers });
  };

  handleDateChange = stateName => date => {
    this.setState({
      [stateName]: date
    });
  };

  handleSubmit = async event => {
    event.preventDefault();
    const data = {
      effectiveDate: this.state.effectiveDate,
      fundId: this.state.fundId,
      issuerId: this.state.issuerId,
      maturityDate: this.state.maturityDate,
      valueDate: this.state.valueDate,
      marketValue: this.state.marketValue,
      note: this.state.note,
      principalAmount: this.state.principalAmount,
      rate: this.state.rate,
      tenor: this.state.tenor
    };

    const editURL = `${process.env.REACT_APP_BASE_URL}/disinvestment/${this.props.id}`;
    const createURL = `${process.env.REACT_APP_BASE_URL}/disinvestment`;

    try {
      this.setState({ isSubmitting: true });
      const res = axios({
        method: this.props.editMode ? "PUT" : "POST",
        url: this.props.editMode ? editURL : createURL,
        data: data,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.token}`
        }
      });
      const { status, data: result } = await res;

      let successMessageEdit = "Disinvestment details updated successfully.";
      let successMessageCreate = "Disinvestment creation success.";
      if (status > 199 && status < 299) {
        toast.success(
          `${this.props.editMode ? successMessageEdit : successMessageCreate}`
        );
      }

      if (this.state.approvers.length) {
        let params = {
          id: result.id,
          instructionType: "disinvestment",
          token: this.props.token,
          approvers: this.state.approvers,
          kind: this.state.isSettlementApproval ? "SETTLEMENT" : "PRE_TRADE"
        };
        await handleCreateApproval(params);
      }

      // no use

      this.props.unsetDisinvestments();
      if (this.props.editMode) {
        this.setState({
          isSubmitting: false
        });
      } else {
        this.setState({
          ...initialState,
          isSubmitting: false
        });
      }
    } catch (err) {
      toast.error("Something went wrong. Please try again.");
      this.setState({ isSubmitting: false });
      console.error(err);
    }
  };

  render() {
    const isApprover = this.props.user.role === "APPROVER";
    const displayApprovers = showApprovers(
      this.state.status,
      this.state.isSettlementApproval
    );

    return (
      <Card className="p-4rem flex-column">
        {this.state.loaded ? (
          <div style={{ width: "70%" }}>
            <h3>
              {this.props.editMode
                ? "Edit Disinvestment Instruction"
                : "Create Disinvestment Instruction"}
            </h3>
            <form onSubmit={this.handleSubmit}>
              <h4 className="mb-2rem">Select Account</h4>
              <select
                onChange={this.handleFund}
                name="fundId"
                value={this.state.fundId}
                disabled={isApprover}
                className="form-input form-input--lg"
              >
                <option value="">Select a fund</option>
                {this.props.funds
                  ? this.props.funds.map(fund => (
                      <option key={fund.id} value={fund.id}>
                        {fund.name}
                      </option>
                    ))
                  : null}
              </select>
              <div className="row mt-2rem mb-2rem">
                <div className="column">
                  <label className="mb-1rem">CSD Account Number</label>
                  <input
                    className="form-input form-input--lg"
                    type="text"
                    placeholder=""
                    value={this.state.csdAccountNumber}
                    readOnly
                    disabled={isApprover}
                  />
                </div>
                <div className="column">
                  <label className="mb-1rem">Cash Account Number</label>
                  <input
                    className="form-input form-input--lg"
                    type="text"
                    placeholder=""
                    value={this.state.bankAccountNumber}
                    readOnly
                    disabled={isApprover}
                  />
                </div>
              </div>

              <hr className="mt-4rem mb-4rem" />

              <h4>Details</h4>

              <div className="row mt-2rem mb-2rem">
                <div className="column">
                  <label className="mb-1rem">Principal Amount</label>
                  <input
                    className="form-input form-input--lg"
                    type="number"
                    step="0.01"
                    placeholder=""
                    onChange={this.handleInput}
                    value={this.state.principalAmount}
                    name="principalAmount"
                    disabled={isApprover}
                  />
                </div>
                <div className="column">
                  <label className="mb-1rem">Issuer</label>
                  <select
                    onChange={this.handleInput}
                    name="issuerId"
                    value={this.state.issuerId}
                    disabled={isApprover}
                    className="form-input form-input--lg"
                  >
                    <option value="">Select one</option>
                    {this.props.issuers
                      ? this.props.issuers.map(issuer => (
                          <option key={issuer.id} value={issuer.id}>
                            {issuer.beneficiary} ({issuer.number})
                          </option>
                        ))
                      : null}
                  </select>
                </div>
              </div>
              <div className="row mt-2rem mb-2rem">
                <div className="column">
                  <label className="mb-1rem">Tenor</label>
                  <input
                    className="form-input form-input--lg"
                    type="number"
                    step="0.01"
                    max="365"
                    min="1"
                    placeholder=""
                    name="count"
                    value={this.state.tenor.count}
                    onChange={this.handleNested("tenor")}
                    disabled={isApprover}
                  />
                </div>
                <div className="column">
                  <label className="mb-1rem">Period</label>
                  <select
                    onChange={this.handleNested("tenor")}
                    name="period"
                    value={this.state.tenor.period}
                    className="form-input form-input--lg"
                    disabled={isApprover}
                  >
                    <option value="">Select one</option>
                    {Object.keys(PERIOD).map(key => (
                      <option key={key} value={key}>
                        {PERIOD[key]}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row mt-2rem mb-2rem">
                <div className="column">
                  <label className="mb-1rem">Rate</label>
                  <input
                    className="form-input form-input--lg"
                    type="number"
                    step="0.01"
                    placeholder=""
                    onChange={this.handleInput}
                    value={this.state.rate}
                    name="rate"
                    disabled={isApprover}
                  />
                </div>
                <div className="column">
                  <label className="mb-1rem">Market Value</label>
                  <input
                    className="form-input form-input--lg"
                    type="number"
                    step="0.01"
                    placeholder=""
                    onChange={this.handleInput}
                    value={this.state.marketValue}
                    name="marketValue"
                    disabled={isApprover}
                  />
                </div>
              </div>
              <div className="row mt-2rem mb-2rem">
                <div className="column">
                  <label className="mb-1rem">Effective Date</label>
                  <DatePicker
                    className="format-input"
                    selected={this.state.effectiveDate}
                    onChange={this.handleDateChange("effectiveDate")}
                    dateFormat="dd/MM/yyyy"
                    disabled={isApprover}
                  />
                </div>
                <div className="column">
                  <label className="mb-1rem">Maturity Date</label>
                  <DatePicker
                    className="format-input"
                    selected={this.state.maturityDate}
                    onChange={this.handleDateChange("maturityDate")}
                    dateFormat="dd/MM/yyyy"
                    disabled={isApprover}
                  />
                </div>
              </div>
              <div className="row mt-2rem mb-2rem">
                <div className="column">
                  <label className="mb-1rem">Value Date</label>
                  <DatePicker
                    className="format-input"
                    selected={this.state.valueDate}
                    onChange={this.handleDateChange("valueDate")}
                    dateFormat="dd/MM/yyyy"
                    disabled={isApprover}
                  />
                </div>
              </div>

              <div className="row mt-2rem mb-2rem">
                <div className="column">
                  <label className="mb-1rem">Note</label>
                  <textarea
                    className="form-textarea"
                    name="note"
                    value={this.state.note}
                    onChange={this.handleInput}
                    cols="30"
                    rows="6"
                    disabled={isApprover}
                  ></textarea>
                </div>
              </div>

              {!isApprover && (
                <React.Fragment>
                  <hr className="mt-4rem mb-4rem" />

                  {displayApprovers && (
                    <React.Fragment>
                      <h4 className="mt-2rem">Co-signers:</h4>
                      {this.props.approvers
                        ? this.props.approvers.map(approver => (
                            <div key={approver.id}>
                              <label htmlFor="">
                                <input
                                  disabled={this.props.user.id === approver.id}
                                  type="checkbox"
                                  onChange={this.handleCheckbox}
                                  value={approver.id}
                                  name=""
                                  id=""
                                />
                                <span className="ml-1rem">
                                  {approver.firstName} {approver.lastName}
                                </span>
                              </label>
                            </div>
                          ))
                        : null}
                    </React.Fragment>
                  )}

                  <label htmlFor="">
                    <input
                      className="mt-4rem"
                      type="checkbox"
                      value={this.state.isSettlementApproval}
                      name="isSettlementApproval"
                      onChange={this.handleInput}
                    />
                    <span className="ml-1rem">Create settlement approval</span>
                  </label>

                  <hr className="mt-4rem mb-4rem" />

                  <div className="mt-2rem"></div>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={this.state.isSubmitting}
                  >
                    {this.props.editMode
                      ? "Save and Update"
                      : "Create Instruction"}
                  </Button>
                </React.Fragment>
              )}
            </form>
          </div>
        ) : (
          "Please wait..."
        )}
      </Card>
    );
  }
}

export default connect(
  state => ({
    token: state.token,
    funds: state.funds,
    approvers: state.approvers,
    user: state.user,
    csdAccounts: state.csdAccounts,
    bankAccounts: state.bankAccounts,
    issuers: state.bankAccounts.filter(bankAccount => {
      return bankAccount.isIssuer;
    })
  }),
  dispatch => ({
    unsetDisinvestments: () => dispatch(unsetDisinvestments()),
    setFunds: funds => dispatch(setFunds(funds))
  })
)(Disinvestment);
